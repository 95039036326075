import {AdminContainer} from '../components/layout'
import {ClientContainer} from '../components/layout/ClientContainer'
import {PublicContainer} from '../components/layout/PublicContainer'
import {AttendanceCalendarPage} from '../features/Attendance/pages/AttendanceCalendarPage'
import {AttendanceDetailPage} from '../features/Attendance/pages/AttendanceDetailPage'
import {AttendanceEvolutionDetailPage} from '../features/Attendance/pages/AttendanceInterventionDetailPage'
import {AttendanceListPage} from '../features/Attendance/pages/AttendanceListPage'
import {LoginPage} from '../features/Auth/pages/LoginPage'
import {ProfilePage} from '../features/Auth/pages/ProfilePage'
import {EstimateDetailPage} from '../features/Estimate/pages/EstimateDetailPage'
import {EstimateListPage} from '../features/Estimate/pages/EstimateListPage'
import {PatientDetailPage} from '../features/Patient/pages/PatientDetailPage'
import {PatientListPage} from '../features/Patient/pages/PatientListPage'
import {ProcedureDetailPage} from '../features/Procedure/pages/ProcedureDetailPage'
import {ProcedureListPage} from '../features/Procedure/pages/ProcedureListPage'
import {TeamDetailPage} from '../features/Team/pages/TeamDetailPage'
import {TeamListPage} from '../features/Team/pages/TeamListPage'
import {ExpenseDetailPage} from '../features/Transaction/pages/ExpenseDetailPage'
import {IncomeDetailPage} from '../features/Transaction/pages/IncomeDetailPage'
import {TransactionListPage} from '../features/Transaction/pages/TransactionListPage'

export const admin_routes = [
  {
    component: PublicContainer,
    routes: [
      {
        path: '/login',
        component: LoginPage
      },
      {
        component: AdminContainer,
        routes: [
          {
            path: '/',
            exact: true,
            component: AttendanceCalendarPage
          },
          {
            path: '/profile',
            component: ProfilePage
          },
          {
            path: '/patients',
            component: PatientListPage,
            routes: [
              {
                path: '/patients/:item_id',
                component: PatientDetailPage
              }
            ]
          },
          {
            path: '/team',
            component: TeamListPage,
            routes: [
              {
                path: '/team/:item_id',
                component: TeamDetailPage
              }
            ]
          },
          {
            path: '/procedures',
            component: ProcedureListPage,
            routes: [
              {
                path: '/procedures/:item_id',
                component: ProcedureDetailPage
              }
            ]
          },
          {
            path: '/estimates',
            component: EstimateListPage,
            routes: [
              {
                path: '/estimates/:item_id',
                component: EstimateDetailPage
              }
            ]
          },
          {
            path: '/attendances-list',
            component: AttendanceListPage,
            routes: [
              {
                path: '/attendances-list/:item_id',
                component: AttendanceDetailPage,

                routes: [
                  {
                    path: '/attendances-list/:attendance_id/evolutions/:item_id',
                    component: AttendanceEvolutionDetailPage
                  }
                ]
              }
            ]
          },
          {
            path: '/attendances',
            component: AttendanceCalendarPage,
            routes: [
              {
                path: '/attendances/:item_id',
                component: AttendanceDetailPage,

                routes: [
                  {
                    path: '/attendances/:attendance_id/evolutions/:item_id',
                    component: AttendanceEvolutionDetailPage
                  }
                ]
              }
            ]
          },
          {
            path: '/transactions',
            component: TransactionListPage,
            routes: [
              {
                path: '/transactions/income/:item_id',
                component: IncomeDetailPage
              },
              {
                path: '/transactions/expense/:item_id',
                component: ExpenseDetailPage
              }
            ]
          }
        ]
      }
    ]
  }
]

export const client_routes = [
  {
    component: PublicContainer,
    routes: [
      {
        path: '/login',
        component: LoginPage
      },
      {
        component: ClientContainer
      }
    ]
  }
]

export const login_only_routes = [
  {
    component: PublicContainer,
    routes: [
      {
        path: '/login',
        component: LoginPage
      }
    ]
  }
]
