import {LockOutlined, UserOutlined} from '@ant-design/icons'
import {Button, Divider, Form, Layout} from 'antd'
import {Formik} from 'formik'
import * as React from 'react'
import {useMutation} from 'react-query'
import {RouteComponentProps} from 'react-router-dom'
import * as Yup from 'yup'
import logo from '../../../assets/logo_team.png'
import {FInput} from '../../../components/form/FormInputs'
import {useAppStore} from '../../../components/hooks'
import InfoVersion from '../../../components/layout/InfoVersion'
import queryClient from '../../../services/queryClient'
import {qs} from '../../queries'

type Props = RouteComponentProps<{ path?: string }>

export function LoginPage(props: Props) {
  // @ts-ignore
  const setAuthentication = useAppStore(state => state.setAuthentication)

  const login = useMutation(qs.users.getAuthToken, {
    onSuccess: async () => {
      props.history.push('/attendances')
    }
  })

  React.useEffect(() => {
    queryClient.cancelQueries()
    queryClient.removeQueries()
    queryClient.clear()

    localStorage.clear()

    setAuthentication(false)
  }, [])

  return (
    <React.Fragment>
      <Layout style={{ minHeight: '100vh', backgroundColor: '#171717' }}>
        <div className="login-page">


          <h2><img style={{ width: '100%' }} src={logo}/></h2>
          <Divider/>

          <Formik
            initialValues={{
              username: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              username: Yup.string().required('Campo obrigatório'),
              password: Yup.string().required('Campo obrigatório')
            })}
            onSubmit={async (values) => {
              login.mutate(values)
            }}
          >
            {(bag) => (
              <Form onFinish={bag.handleSubmit}>
                <FInput autoFocus prefix={<UserOutlined/>} placeholder="Usuário" name="username" size="large"/>
                <FInput prefix={<LockOutlined/>} type="password" placeholder="Senha" name="password" size="large"/>

                <br/>

                <Button type="primary" htmlType="submit" className="login-form-button" size={'large'} loading={login.isLoading}>Entrar</Button>
              </Form>
            )}
          </Formik>

          <InfoVersion/>
        </div>
      </Layout>
    </React.Fragment>
  )
}

