import {DeleteOutlined} from '@ant-design/icons'
import {Alert, Button, Divider, Form, Modal, Popconfirm} from 'antd'
import currency from 'currency.js'
import {FieldArray, FormikProvider, useFormik} from 'formik'
import moment from 'moment'
import * as React from 'react'
import {useEffect} from 'react'
import {useMutation} from 'react-query'
import * as Yup from 'yup'
import {FDate, FInputNumber, FMoney, FormGroup, FSelect} from '../../../components/form/FormInputs'
import {Dec2BRL} from '../../../utils/formatters'
import {PAY_SELECT} from '../../constants'
import {qs} from '../../queries'

type InvoiceModalPropsType = {
  estimate_id: number
  visible: boolean
  total_amount: number
  closeModal: () => void
}

export const CreateInvoiceModal: React.FC<InvoiceModalPropsType> = (props) => {
  const create = useMutation(values => qs.estimates.createInvoice(props.estimate_id, values), {
    onSuccess: () => props.closeModal()
  })

  const formikBag = useFormik<any>({
    enableReinitialize: true,
    initialValues: {
      installments_qtd: 1,
      def_payment_type: '',
      down_payment: '0',
      first_due_date: '',
      installments: [
        {
          due_date: '',
          payment_type: '',
          amount: props.total_amount
        }
      ]

    },
    validationSchema: Yup.object().shape({
      installments_qtd: Yup.string().required(),
      def_payment_type: Yup.string().required(),
      first_due_date: Yup.string().required(),

      installments: Yup.array(Yup.object().shape({
        due_date: Yup.string().required(),
        payment_type: Yup.string().required(),
        amount: Yup.string().required()
      }))
    }),
    validate: (values) => {
      let errors: any = {}
      if (getSumAll() !== props.total_amount) {
        errors['total_amount'] = true
      }
      return errors
    },
    onSubmit: (values: any) => {
      create.mutate(values.installments)
    }
  })

  useEffect(() => {
    const installments_qtd = formikBag?.values?.installments_qtd
    const def_payment_type = formikBag?.values?.def_payment_type
    const down_payment = currency(formikBag?.values?.down_payment)
    let first_due_date = formikBag?.values?.first_due_date

    if (installments_qtd && def_payment_type) {

      let _installments: any[] = []

      if (first_due_date) {
        let installments_amount = currency(props.total_amount)

        if (down_payment.intValue) {
          installments_amount = installments_amount.subtract(down_payment)

          _installments.push({
            due_date: first_due_date,
            payment_type: def_payment_type,
            amount: down_payment.value,
            is_down_payment: true
          })

          first_due_date = moment(first_due_date).add(1, 'month').format('YYYY-MM-DD')

        }

        const installs = installments_amount.distribute(installments_qtd)

        installs.forEach(i => {
          let item = {
            due_date: first_due_date,
            payment_type: def_payment_type,
            amount: i.value
          }

          first_due_date = moment(first_due_date).add(1, 'month').format('YYYY-MM-DD')

          _installments.push(item)
        })
      }

      formikBag.setFieldValue('installments', _installments)
    }

  }, [
    formikBag?.values?.def_payment_type,
    formikBag?.values?.down_payment,
    formikBag?.values?.installments_qtd,
    formikBag?.values?.first_due_date
  ])

  const getSumAll = () => {
    let sum = currency(0)

    if (formikBag.values.installments) {

      for (let i of formikBag.values.installments) {
        sum = sum.add(i.amount)
      }
    }

    return sum.value
  }

  return (
    <>
      <Modal
        visible={true}
        maskClosable
        destroyOnClose
        onCancel={props.closeModal}
        width={700}
        closable={false}
        footer={[
          <Button key="2" type="default" onClick={props.closeModal}>Fechar</Button>,
          <Button key="1" type="primary" onClick={formikBag.submitForm} loading={create.isLoading}>Salvar</Button>
        ]}
      >
        <FormikProvider value={formikBag}>
          <Form layout="vertical">
            <FSelect name="def_payment_type" label="Forma de pagamento" options={PAY_SELECT}/>
            <FormGroup>
              <FInputNumber name="installments_qtd" label="Quantidade de parcelas" min={1}/>
              <FDate name="first_due_date" label="Vencimento da primeira parcela"/>
              <FMoney name="down_payment" label="Valor Entrada"/>
            </FormGroup>

            <FieldArray
              name="installments"
              render={arrayHelpers => (
                <>
                  <div className="ant-table">
                    <div className="ant-table-container">
                      <table>
                        <thead className="ant-table-thead">
                        <tr>
                          <th className="ant-table-cell">Vencimento</th>
                          <th className="ant-table-cell">Forma de pagamento</th>
                          <th className="ant-table-cell">Valor</th>
                          <th className="ant-table-cell">Ações</th>
                        </tr>
                        </thead>

                        <tbody className="ant-table-tbody">
                        {formikBag.values?.installments?.map((item, index) => (
                          <>
                            {!item.is_down_payment &&
                              <tr key={index} className="ant-table-row ant-table-row-level-0">
                                <td className="ant-table-cell">
                                  <FDate name={`installments[${index}].due_date`} label=" "/>
                                </td>

                                <td className="ant-table-cell">
                                  <FSelect name={`installments[${index}].payment_type`} label=" " options={PAY_SELECT}/>
                                </td>

                                <td className="ant-table-cell">
                                  <FMoney name={`installments[${index}].amount`} label=" "/>
                                </td>

                                <td className="ant-table-cell">
                                  <Popconfirm
                                    title="Tem certeza que gostaria de excluir esse item?"
                                    onConfirm={() => {
                                      arrayHelpers.remove(index)
                                    }}
                                    okText="Sim, excluir!"
                                    cancelText="Não"
                                  >
                                    <Button size="small" danger shape="circle" icon={<DeleteOutlined/>}/>
                                  </Popconfirm>
                                </td>
                              </tr>}
                          </>
                        ))}
                        </tbody>

                      </table>
                    </div>
                  </div>
                </>
              )}
            />

            <Divider type="horizontal"/>

            {formikBag.errors.total_amount && <Alert message="O valor total das parcelas é diferente do orçamento!" type="error" showIcon/>}

            <h3 style={{ textAlign: 'right' }}>Total: {Dec2BRL(getSumAll())}</h3>
          </Form>
        </FormikProvider>
      </Modal>
    </>
  )
}