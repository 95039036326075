import {Button, Col, Form, Modal, Row, Tabs} from 'antd'
import {FormikProvider, useFormik} from 'formik'
import * as React from 'react'
import {useEffect} from 'react'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {RouteConfigComponentProps} from 'react-router-config'
import {useHistory, useParams} from 'react-router-dom'
import * as Yup from 'yup'
import {FCheckbox, FInput, FormGroup, FSelect} from '../../../components/form/FormInputs'
import {ROLES_SELECT} from '../../constants'
import {qs} from '../../queries'
import {UserModel} from '../../typings'

type TeamDetailPageProps = RouteConfigComponentProps

type FormValues = { [K in keyof UserModel]?: UserModel[K] | '' } & { new_password: string }

export const TeamDetailPage: React.FC<TeamDetailPageProps> = () => {
  const { item_id } = useParams<{ item_id: string }>()
  const history = useHistory()
  const queryClient = useQueryClient()

  const cur_item = useQuery(['getUser', item_id], () => qs.users.get(item_id), {
    enabled: false,
    retry: false
  })

  const create = useMutation(values => qs.users.post(values))
  const update = useMutation(values => qs.users.patch(values))

  useEffect(() => {
    cur_item.refetch()
  }, [item_id])

  let mutating = create.isLoading || update.isLoading

  const handleCancel = () => {
    queryClient.removeQueries(['getUser', item_id], { inactive: true, stale: true })

    setTimeout(() => {
      history.push('/team')
    }, 200)
  }

  const formikBag = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      id: cur_item.data?.id ?? '',
      role: cur_item.data?.role ?? '',
      username: cur_item.data?.username ?? '',
      is_active: cur_item.data?.is_active ?? false,
      name: cur_item.data?.name ?? '',
      cpf: cur_item.data?.cpf ?? '',
      email: cur_item.data?.email ?? '',
      phone: cur_item.data?.phone ?? '',
      phone_mobile: cur_item.data?.phone_mobile ?? '',
      address: cur_item.data?.address ?? '',
      city: cur_item.data?.city ?? '',
      state: cur_item.data?.state ?? '',
      cep: cur_item.data?.cep ?? '',
      bank: cur_item.data?.bank ?? '',
      bank_agency: cur_item.data?.bank_agency ?? '',
      bank_account: cur_item.data?.bank_account ?? '',
      pis: cur_item.data?.pis ?? '',
      deadline_contract: cur_item.data?.deadline_contract ?? '',
      new_password: ''
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(),
      username: Yup.string().required(),
      role: Yup.string().required(),
      email: Yup.string().email().required()
    }),
    onSubmit: (values: any) => {
      if (cur_item.data?.id) {
        update.mutate(values)
      } else {
        create.mutate(values)
      }
    }
  })

  return (
    <React.Fragment>
      <Modal
        visible={true}
        maskClosable
        destroyOnClose
        onCancel={handleCancel}
        width={900}
        closable={false}
        className="ant-modal-tabs-only"
        footer={[
          <Button key="2" type="default" onClick={handleCancel}>Fechar</Button>,
          <Button key="1" type="primary" onClick={formikBag.submitForm} loading={mutating}>Salvar</Button>
        ]}
      >
        {!cur_item.isLoading &&
          <FormikProvider value={formikBag}>
            <Form layout="vertical">
              <Tabs>
                <Tabs.TabPane tab="Dados Pessoais" key="1">
                  <Row gutter={48}>
                    <Col span={24}>
                      <FormGroup>
                        <FInput name="name" label="Nome"/>
                        <FInput name="cpf" label="CPF"/>
                      </FormGroup>

                      <FormGroup>
                        <FInput name="email" label="E-mail"/>
                        <FInput name="phone_mobile" label="Celular"/>
                        <FInput name="phone" label="Telefone"/>
                      </FormGroup>

                      <FInput name="address" label="Endereço"/>
                      <FormGroup>
                        <FInput name="city" label="Cidade"/>
                        <FInput name="state" label="Estado"/>
                        <FInput name="cep" label="CEP"/>
                      </FormGroup>
                    </Col>
                  </Row>
                </Tabs.TabPane>

                <Tabs.TabPane tab="Acesso" key="4">
                  <FormGroup>
                    <FInput name="username" label="Usuário"/>
                    <FInput name="new_password" label="Nova Senha" type="password"/>
                    <FSelect name="role" label="Perfil" options={ROLES_SELECT}/>
                  </FormGroup>

                  <FCheckbox name="is_active" label="Ativo"/>
                </Tabs.TabPane>
              </Tabs>
            </Form>
          </FormikProvider>}
      </Modal>
    </React.Fragment>
  )
}
