import {MenuOutlined} from '@ant-design/icons'
import {Button, Col, Form, Layout, PageHeader, Row, Tabs} from 'antd'
import {FormikProvider, useFormik} from 'formik'
import * as React from 'react'
import {useMutation} from 'react-query'
import {useRecoilState} from 'recoil'
import * as Yup from 'yup'
import {FCPF, FInput, FormGroup} from '../../../components/form/FormInputs'
import {useMeQuery} from '../../../components/hooks'
import {menuCollapseState} from '../../../state/atoms'
import {qs} from '../../queries'
import {UserModel} from '../../typings'

type FormValues = { [K in keyof UserModel]?: UserModel[K] | '' } & { new_password: string }

export const ProfilePage: React.FC = (props) => {
  let title = 'Perfil'

  const { me, isLoading } = useMeQuery()

  const update = useMutation(values => qs.users.updateMe(values))
  const [collapsed, setCollapsed] = useRecoilState(menuCollapseState)

  const formikBag = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      id: me?.id ?? '',
      name: me?.name ?? '',
      cpf: me?.cpf ?? '',
      email: me?.email ?? '',
      phone: me?.phone ?? '',
      phone_mobile: me?.phone_mobile ?? '',
      address: me?.address ?? '',
      city: me?.city ?? '',
      state: me?.state ?? '',
      cep: me?.cep ?? '',
      bank: me?.bank ?? '',
      bank_agency: me?.bank_agency ?? '',
      bank_account: me?.bank_account ?? '',
      pis: me?.pis ?? '',
      new_password: ''
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(),
      email: Yup.string().email().required()
    }),
    onSubmit: (values: any) => {
      if (me?.id) {
        update.mutate(values)
      }
    }
  })

  return (
    <React.Fragment>
      <PageHeader
        ghost={false}
        title={title}
        onBack={() => setCollapsed(!collapsed)}
        backIcon={<MenuOutlined/>}
      />

      <Layout.Content className="body-container">

        {!isLoading &&
          <FormikProvider value={formikBag}>
            <Form layout="vertical">
              <Tabs>
                <Tabs.TabPane tab="Dados Pessoais" key="1">
                  <Row gutter={48}>
                    <Col span={24}>
                      <FormGroup>
                        <FInput name="name" label="Nome"/>
                        <FCPF name="cpf" label="CPF"/>
                      </FormGroup>
                      <FormGroup>
                        <FInput name="email" label="E-mail"/>
                        <FInput name="phone" label="Telefone Próprio"/>
                        <FInput name="phone_emergency" label="Telefone Emergência/Recado"/>
                      </FormGroup>

                      <FInput name="address" label="Endereço"/>
                      <FormGroup>
                        <FInput name="city" label="Cidade"/>
                        <FInput name="state" label="Estado"/>
                        <FInput name="cep" label="CEP"/>
                      </FormGroup>
                    </Col>
                  </Row>
                </Tabs.TabPane>

                <Tabs.TabPane tab="Acesso" key="4">
                  <b>Usuário: {me?.username}</b> <br/><br/>
                  <FormGroup>
                    <FInput name="new_password" label="Nova Senha" type="password"/>
                  </FormGroup>
                </Tabs.TabPane>
              </Tabs>

              <Button key="1" type="primary" onClick={formikBag.submitForm} loading={update.isLoading}>Salvar</Button>
            </Form>
          </FormikProvider>}
      </Layout.Content>
    </React.Fragment>
  )
}
