import {loadMessages as devExtremeLoadMessages, locale as devExtremeLocale} from 'devextreme/localization'
import ptMessages from 'devextreme/localization/messages/pt.json'
import React, {useEffect} from 'react'
import {renderRoutes} from 'react-router-config'
import {useHistory, useLocation} from 'react-router-dom'
import {setLocale} from 'yup'
import {useAppStore, useMeQuery} from './components/hooks'
import {admin_routes, client_routes, login_only_routes} from './router'

devExtremeLoadMessages(ptMessages)
devExtremeLocale('pt-BR')

setLocale({
  mixed: {
    default: 'Não é válido',
    notType: 'Valor não é válido',
    required: 'Campo obrigatório'
  },
  string: {
    email: 'Email não é válido'
  },
  number: {
    min: 'Deve ser maior que ${min}'
  }
})

function App() {
  const history = useHistory()
  const location = useLocation()
  const { is_client, me, is_admin_or_assistant_or_is_doctor, refetch } = useMeQuery()

  // @ts-ignore
  const is_authenticated = useAppStore(state => state.is_authenticated)
  // @ts-ignore
  const setAuthentication = useAppStore(state => state.setAuthentication)

  useEffect(() => {
    if (!is_authenticated) {
      history.push('/login')
      setAuthentication(false)
    }

  }, [])

  useEffect(() => {
    if (is_authenticated) {
      refetch().then(() => {
        if (location.pathname.indexOf('login') > -1) {
          history.push('/attendances')
        }
      })
    }

  }, [is_authenticated])

  if (!me?.id && !is_authenticated) {
    return (
      <div className="App">
        {renderRoutes(login_only_routes)}
      </div>
    )
  }

  if (is_client) {
    return (
      <div className="App">
        {renderRoutes(client_routes)}
      </div>
    )
  }

  if (is_admin_or_assistant_or_is_doctor) {
    return (
      <div className="App">
        {renderRoutes(admin_routes)}
      </div>
    )
  }

  return (
    <div className="App">

    </div>
  )
}

export default App
