import {notification} from 'antd'

type Errors = {
  code?: string[] | string
  message?: string[] | string
  errors?: Error[]
  non_field_errors?: string[] | string
  detail?: string
  success?: boolean
}

type Error = {
  code?: string
  field?: string
  message?: string
}

type Exc = {
  response?: {
    data?: string[] | Errors
  }
}

const notify_error = (msg: string) => {
  notification.error({ message: msg })
}

export default class toasterService {
  static sendErrors(error: Exc) {
    const error_data = error?.response?.data

    console.log('error_data', error_data)

    if (error_data && !Array.isArray(error_data) && Array.isArray(error_data.errors)) {
      /*
            {
              "code": "1000",
              "message": "Dados inválidos",
              "errors": [
              {
                "code": "2012",
                "field": "Email",
                "message": "Insira um endereço de email válido."
              }
            ]
            }
      */

      console.log('ET-1')

      for (let o of error_data.errors) {
        notify_error(o.message as string)
      }

      return
    }

    if (error_data && !Array.isArray(error_data) && typeof error_data.message === 'string') {
      /*
            {
              "message": "Condomínio não existe!",
              "success": false
            }
      */

      console.log('ET-2')

      notify_error(error_data.message)

      return
    }

    if (error_data && !Array.isArray(error_data) && Array.isArray(error_data.message)) {
      /*
            {
              "message": ["Err!"],
              "success": false
            }


            {
              "code": [
                "1000"
              ],
              "message": [
                "Dados inválidos"
              ],
              "errors": [
                {
                  "code": "1001",
                  "field": "non_field_error",
                  "message": "Não é possível reservar pois existem reservas nos espaços CAMPO DE FUTEBOL para a data selecionada!"
                }
              ]
            }
      */

      console.log('ET-3')

      for (let o of error_data.message) {
        notify_error(o)
      }

      if (Array.isArray(error_data.errors)) {
        for (let o of error_data.errors) {
          notify_error(o.message as string)
        }
      }

      return
    }

    if (error_data && !Array.isArray(error_data) && typeof error_data.non_field_errors === 'string') {
      /*
            {
              "non_field_errors": "Condomínio não existe!",
              "success": false
            }
      */

      console.log('ET-4')

      notify_error(error_data.non_field_errors)

      return
    }

    if (error_data && !Array.isArray(error_data) && Array.isArray(error_data.non_field_errors)) {
      /*
            {
              "non_field_errors": ["Err!"],
              "success": false
            }
      */

      console.log('ET-5')

      for (let o of error_data.non_field_errors) {
        notify_error(o)
      }

      return
    }

    if (error_data && !Array.isArray(error_data) && error_data.detail) {
      /*
            {
              "detail": "Condomínio não existe!",
            }
      */

      console.log('ET-6')

      notify_error(error_data.detail)

      return
    }

    if (error_data && Array.isArray(error_data)) {
      /*
            ["Erro ..."]
      */

      console.log('ET-7')

      error_data.forEach((item) => {
        notify_error(item)
      })

      return
    }

    if (error_data && !Array.isArray(error_data) && typeof (error_data) === 'object') {
      /*
            {
              "non_field_errors": ["Impossível fazer login com as credenciais fornecidas."]
            }


            {
              "nome do campo": ["Campo obrigatório."]
            }
      */

      console.log('ET-8')

      for (let k of Object.values(error_data)) {
        console.log('k', k)

        if (Array.isArray(k)) {
          for (let i of k) {
            console.log('i', i)
            notify_error(i + '')
          }
        }
      }

      return
    }

    console.log('ET-9')

    notify_error('Ocorreu algum erro')
  }
}