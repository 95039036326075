import {DeleteOutlined, FileExcelOutlined, MenuOutlined, PlusCircleOutlined, PlusCircleTwoTone, ZoomInOutlined} from '@ant-design/icons'
import {Button, Col, DatePicker, Divider, Form, Input, Layout, PageHeader, Popconfirm, Row, Switch, Table, Tag} from 'antd'
import {ColumnsType} from 'antd/lib/table/interface'
import * as React from 'react'
import {useState} from 'react'
import {IMask} from 'react-imask'
import {useMutation, useQuery} from 'react-query'
import {renderRoutes, RouteConfigComponentProps} from 'react-router-config'
import {Link, useHistory} from 'react-router-dom'
import {useRecoilState} from 'recoil'
import * as XLSX from 'xlsx'
import {menuCollapseState} from '../../../state/atoms'
import {dateFormat, Dec2BRL} from '../../../utils/formatters'
import {PAY_TYPES} from '../../constants'
import {qs} from '../../queries'
import {TransactionModel} from '../../typings'

const queryString = require('query-string')

const AdvancedSearchForm = React.memo(({ setQsQuery, refetch }: { setQsQuery: (data: any) => void, refetch: () => void }) => {
  const [filterForm] = Form.useForm()

  const onFinish = (values: any) => {
    console.log(values)

    if (values['filter{due_date.gte}']) {
      values['filter{due_date.gte}'] = values['filter{due_date.gte}'].format('YYYY-MM-DD')
    }

    if (values['filter{due_date.lte}']) {
      values['filter{due_date.lte}'] = values['filter{due_date.lte}'].format('YYYY-MM-DD')
    }

    for (let a of Object.keys(values)) {
      let _value = values[a]
      if (_value === '' || _value === null || _value === undefined) {
        delete values[a]
      }
    }

    let __qs = queryString.stringify(values, { arrayFormat: 'comma' })

    setQsQuery(__qs)
    setQsQuery(__qs)

    setTimeout(() => refetch(), 200)
  }

  return (
    <Form
      form={filterForm}
      name="advanced_search"
      className="advanced-search-form"
      onFinish={onFinish}
      layout={'vertical'}
    >
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item name="filter{patient.name.icontains}" label="Paciente">
            <Input/>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="filter{patient.cpf.icontains}" label="CPF">
            <Input/>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="filter{description.icontains}" label="Descrição">
            <Input/>
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name="filter{due_date.gte}" label="Data - Início">
            <DatePicker format={'DD/MM/YYYY'}/>
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name="filter{due_date.lte}" label="Data - Término">
            <DatePicker format={'DD/MM/YYYY'}/>
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name="filter{is_paid}" label="Pago">
            <Switch/>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button type="primary" htmlType="submit">
            Filtrar
          </Button>
          <Button
            style={{ margin: '0 8px' }}
            onClick={() => {
              filterForm.resetFields()
              setQsQuery('')
              setQsQuery('')
              setTimeout(() => refetch(), 200)
            }}
          >
            Resetar filtro
          </Button>
        </Col>
      </Row>
    </Form>
  )
})

type Props = RouteConfigComponentProps

const numberFormat = IMask.createPipe({
  mask: Number,
  scale: 2,
  thousandsSeparator: '.',
  radix: ',',
  normalizeZeros: true,
  padFractionalZeros: true
})

export const TransactionListPage: React.FC<Props> = (props) => {
  const [qs_query, setQsQuery] = useState('')
  const [income_total, setIncomeTotal] = useState(0)
  const [expense_total, setExpenseTotal] = useState(0)
  const [collapsed, setCollapsed] = useRecoilState(menuCollapseState)
  const history = useHistory()

  const exportToCsv = () => {
    let sheet_data = data?.map(i => ({
      'Tipo': i.moving_type === 'INCOME' ? 'Cobrança' : 'Despesa',
      'Status': i.is_paid ? 'PAGO' : 'PENDENTE',
      'Data': dateFormat(i.date),
      'Vencimento': dateFormat(i.due_date),
      'Paciente': i.patient_name,
      'Descrição': i.description,
      'Forma de pagamento': PAY_TYPES[i.payment_type],
      'Valor': numberFormat(String(i.amount))
    })) ?? []

    let sheet2 = XLSX.utils.json_to_sheet(sheet_data)

    let wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, sheet2, 'Relatório')

    XLSX.writeFile(wb, `Relatório Financeiro.xlsx`)
  }

  const { isFetching, data, refetch } = useQuery('getTransactions', () => qs.transactions.all(qs_query), {
    onSuccess: (_data) => {
      let _income_total = 0
      let _expense_total = 0

      for (let o of _data) {
        if (o.moving_type === 'INCOME') {
          _income_total += parseFloat(o.amount)
        } else {
          _expense_total += parseFloat(o.amount)
        }
      }

      setIncomeTotal(_income_total)
      setExpenseTotal(_expense_total)
    }
  })
  const destroy = useMutation((values: any) => qs.transactions.delete(values))

  const grid_columns: ColumnsType<TransactionModel> = [
    {
      title: 'Status',
      dataIndex: 'is_paid',
      width: 100,
      render: (value, row) => (
        row.is_paid
          ? <Tag color="green">Pago</Tag>
          : <Tag color="red">Pendente</Tag>
      )
    },
    {
      title: 'Vencimento',
      width: 100,
      dataIndex: 'due_date',
      render: (value) => dateFormat(value)
    },
    {
      title: 'Paciente',
      width: 250,
      dataIndex: 'patient_name'
    },
    {
      title: 'Descrição',
      width: 400,
      dataIndex: 'description'
    },
    {
      title: 'Valor',
      dataIndex: 'amount',
      align: 'right',
      width: 100,
      render: (value, row) => (
        row.moving_type === 'INCOME' ?
          <b style={{ color: '#4caf50' }}>{Dec2BRL(value)}</b>
          :
          <b style={{ color: '#f13250' }}>{Dec2BRL(value)}</b>
      )
    },
    {
      title: 'Ações',
      dataIndex: 'id',
      align: 'right',
      width: 20,
      render: (value, row) => (
        <>
          <Link to={row.moving_type === 'INCOME' ? `/transactions/income/${value}` : `/transactions/expense/${value}`}>
            <ZoomInOutlined style={{ fontSize: '16px', marginRight: 5 }}/>
          </Link>


          <Divider type="vertical"/>

          <Popconfirm
            title="Tem certeza que gostaria de excluir esse item?"
            onConfirm={() => destroy.mutate(row)}
            okText="Sim, excluir!"
            cancelText="Não"
          >
            <DeleteOutlined color="red"/>
          </Popconfirm>
        </>
      )
    }
  ]

  return (
    <React.Fragment>
      <PageHeader
        ghost={false}
        title={'Financeiro'}
        onBack={() => setCollapsed(!collapsed)}
        backIcon={<MenuOutlined/>}
      />

      <AdvancedSearchForm refetch={refetch} setQsQuery={setQsQuery}/>

      <Layout.Content className="body-container">
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
          <div>
            <Button
              type="primary" style={{ background: '#4caf50', borderColor: '#4caf50' }}
              onClick={() => history.push('/transactions/income/new')}
              icon={<PlusCircleOutlined/>}
            >
              Nova Cobrança
            </Button>

            &nbsp;&nbsp;

            <Button type="default" icon={<FileExcelOutlined/>} onClick={exportToCsv}>Exportar para Excel</Button>
          </div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginRight: 30 }}>
              <PlusCircleTwoTone twoToneColor="#4caf50" style={{ fontSize: 30, marginRight: 15 }}/>
              <div>
                Receitas
                <h2 style={{ margin: 0 }}>{Dec2BRL(income_total)}</h2>
              </div>
            </div>
          </div>
        </div>

        <Table columns={grid_columns} dataSource={data} rowKey="id" loading={isFetching}/>

        {renderRoutes(props.route?.routes)}
      </Layout.Content>
    </React.Fragment>
  )
}
