import {MenuOutlined, ZoomInOutlined} from '@ant-design/icons'
import {Button, Checkbox, Col, Form, Input, Layout, PageHeader, Row, Table, Tag} from 'antd'
import {ColumnsType} from 'antd/lib/table/interface'
import * as React from 'react'
import {useState} from 'react'
import {useQuery} from 'react-query'
import {renderRoutes, RouteConfigComponentProps} from 'react-router-config'
import {Link, useHistory} from 'react-router-dom'
import {useRecoilState} from 'recoil'
import {menuCollapseState} from '../../../state/atoms'
import {Dec2BRL} from '../../../utils/formatters'
import {ESTIMATE_STATUS_ENUM} from '../../constants'
import {qs} from '../../queries'
import {EstimateModel} from '../../typings'

const queryString = require('query-string')

const AdvancedSearchForm = React.memo(({ setQsQuery, refetch }: { setQsQuery: (data: any) => void, refetch: () => void }) => {
  const [filterForm] = Form.useForm()

  const onFinish = (values: any) => {
    let __qs = queryString.stringify(values, { arrayFormat: 'comma' })

    setQsQuery(__qs)
    setQsQuery(__qs)

    refetch()
  }

  return (
    <Form
      form={filterForm}
      name="advanced_search"
      className="advanced-search-form"
      onFinish={onFinish}
      layout={'vertical'}
    >
      <Row gutter={24}>
        <Col span={10}>
          <Form.Item name="filter{patient.name.icontains}" label="Paciente">
            <Input/>
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item name={`filter{contract_active}`} valuePropName="checked" label="Contrato">
            <Checkbox/>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button type="primary" htmlType="submit">
            Filtrar
          </Button>
          <Button
            style={{ margin: '0 8px' }}
            onClick={() => {
              filterForm.resetFields()
              setQsQuery('')
              setQsQuery('')
              refetch()
            }}
          >
            Resetar filtro
          </Button>
        </Col>
      </Row>
    </Form>
  )
})

type Props = RouteConfigComponentProps

export const EstimateListPage: React.FC<Props> = (props) => {
  const [qs_query, setQsQuery] = useState('')
  const { isFetching, data, refetch } = useQuery('getEstimates', () => qs.estimates.all(qs_query))
  const history = useHistory()
  const [collapsed, setCollapsed] = useRecoilState(menuCollapseState)

  const grid_columns: ColumnsType<EstimateModel> = [
    {
      title: 'Código',
      width: 60,
      dataIndex: 'id'
    },
    {
      title: 'Paciente',
      width: 300,
      dataIndex: 'patient_name'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 130,
      render: (value) => <Tag>{ESTIMATE_STATUS_ENUM[value]}</Tag>
    },
    {
      title: 'Contrato',
      width: 90,
      dataIndex: 'contract_active',
      render: (value) => value ? 'Sim' : 'Não'
    },
    {
      title: 'Valor',
      width: 90,
      dataIndex: 'total_amount',
      render: (value) => value ? Dec2BRL(value) : Dec2BRL(0)
    },
    {
      title: 'Ações',
      dataIndex: 'id',
      align: 'right',
      width: 20,
      render: (value) => (
        <Link to={`/estimates/${value}`}><ZoomInOutlined style={{ fontSize: '16px', marginRight: 5 }}/></Link>
      )
    }
  ]

  return (
    <React.Fragment>
      <PageHeader
        ghost={false}
        title="Orçamentos/Contratos"
        onBack={() => setCollapsed(!collapsed)}
        backIcon={<MenuOutlined/>}
      />

      <AdvancedSearchForm refetch={refetch} setQsQuery={setQsQuery}/>

      <Layout.Content className="body-container">
        <Button type="primary" onClick={() => history.push('/estimates/new')}>Novo</Button>

        <Table columns={grid_columns} dataSource={data} rowKey="id" loading={isFetching}/>

        {renderRoutes(props.route?.routes)}
      </Layout.Content>
    </React.Fragment>
  )
}
