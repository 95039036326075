import {Alert, Button, Divider, List, Tag} from 'antd'
import * as React from 'react'
import {useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import {dateFormat, Dec2BRL} from '../../../utils/formatters'
import {qs} from '../../queries'
import {CreateInvoiceModal} from './CreateInvoiceModal'

type Props = {
  object_id: number | string | null | undefined
  total_amount: number
}

const EstimateInvoicesTab = (props: Props) => {
  const [modal_visible, setModalVisible] = useState(false)

  const transactions = useQuery(['byEstimate', props.object_id], () => qs.transactions.byEstimate(props.object_id), {
    enabled: false,
    retry: false
  })

  const closeModal = () => {
    setModalVisible(false)
    transactions.refetch()
  }

  useEffect(() => {
    transactions.refetch()
  }, [props.object_id])

  if (!props.object_id) {
    return (
      <Alert message="Crie o item primeiro!" type="info"/>
    )
  }

  return (
    <React.Fragment>
      <Button type="default" onClick={() => setModalVisible(true)}>Gerar Cobranças</Button>

      <br/>
      <br/>

      <Divider style={{ marginTop: 0 }}/>

      <List
        loading={transactions.isLoading}
        itemLayout="horizontal"
        dataSource={transactions.data?.results}
        renderItem={item => (
          <List.Item
            actions={[
              <a href={`/transactions/income/${item.id}`} target="_blank">abrir</a>
            ]}
          >
            <List.Item.Meta
              title={`${dateFormat(item.due_date)} - ${item.description}`}
              description={<>R$ {Dec2BRL(item.amount)} {item.is_paid && <Tag color="green">Pago</Tag>}</>}
            />
          </List.Item>
        )}
      />

      {modal_visible && props.object_id &&
        <CreateInvoiceModal
          estimate_id={props.object_id as number}
          visible={modal_visible}
          closeModal={closeModal}
          total_amount={props.total_amount}
        />}
    </React.Fragment>
  )
}

export default EstimateInvoicesTab