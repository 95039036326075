export const ATTENDANCE_STATUS_MP = {
  'PENDENT': 'Pendente',
  'CANCELED': 'Cancelado',
  'FINISHED': 'Finalizado',
  'CONFIRMED': 'Confirmado'
}

export const ROLES_SELECT = [
  { value: 'DOCTOR', label: 'Dentista' },
  { value: 'ADMIN', label: 'Administrativo' },
  { value: 'RECEPCAO', label: 'Recepção' },
  { value: 'FINANCE', label: 'Financeiro' },
  { value: 'MARKETING', label: 'Marketing' },
  { value: 'ASSISTANT', label: 'Secretária' }
]

export const ESTIMATE_STATUS_SELECT = [
  { value: 'ACTIVE', label: 'Ativo' },
  { value: 'CANCELLED', label: 'Cancelado' },
  { value: 'FINISHED', label: 'Finalizado' },
  { value: 'BLOCKED', label: 'Bloqueado' },
  { value: 'JUDICIAL', label: 'Jurídico' }
]

export const ESTIMATE_STATUS_ENUM = {
  'ACTIVE': 'Ativo',
  'CANCELLED': 'Cancelado',
  'FINISHED': 'Finalizado',
  'BLOCKED': 'Bloqueado',
  'JUDICIAL': 'Jurídico'
}

export const ROLES_SELECT_MP = {
  'DOCTOR': 'Dentista',
  'ADMIN': 'Administrativo',
  'ASSISTANT': 'Secretária'
}

export const PAY_TYPES = {
  'BOLETO': 'Boleto',
  'WIRE': 'Transferência',
  'CC': 'Cartão de crédito (maquininha)',
  'CC_ONLINE': 'Cartão de crédito (online)',
  'CHECK': 'Cheque',
  'MONEY': 'Dinheiro',
  'PIX_PHONE': 'PIX Celular',
  'PIX_CNPJ': 'PIX Cnpj',
  'PIX_EMAIL': 'PIX Email'
}

export const PAY_SELECT = [
  { value: 'BOLETO', label: 'Boleto' },
  { value: 'WIRE', label: 'Transferência' },
  { value: 'CC', label: 'Cartão de crédito (maquininha)' },
  { value: 'CC_ONLINE', label: 'Cartão de crédito (online)' },
  { value: 'CHECK', label: 'Cheque' },
  { value: 'MONEY', label: 'Dinheiro' },

  { value: 'PIX_PHONE', label: 'PIX Celular' },
  { value: 'PIX_CNPJ', label: 'PIX Cnpj' },
  { value: 'PIX_EMAIL', label: 'PIX Email' }
]
