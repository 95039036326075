import {ExclamationCircleOutlined} from '@ant-design/icons'
import {Button, Col, Form, Input, Layout, Modal, notification, Row, Spin} from 'antd'

import Scheduler, {Resource} from 'devextreme-react/scheduler'
import 'devextreme/dist/css/dx.common.css'

import 'devextreme/dist/css/dx.light.css'
import {AppointmentClickEvent, AppointmentDblClickEvent, AppointmentUpdatingEvent} from 'devextreme/ui/scheduler'
import * as React from 'react'
import {useState} from 'react'

import {useMutation, useQuery} from 'react-query'
import {useMediaQuery} from 'react-responsive'
import {renderRoutes, RouteConfigComponentProps} from 'react-router-config'
import {useHistory} from 'react-router-dom'
import {CustomPageHeader} from '../../../components/layout/CustomPageHeader'
import {qs} from '../../queries'

const Color = require('color')

const views = ['day', 'week', 'month', 'agenda']
type Props = RouteConfigComponentProps

const queryString = require('query-string')

const AdvancedSearchForm = React.memo(({ setQsQuery, refetch }: { setQsQuery: (data: any) => void, refetch: () => void }) => {
  const [filterForm] = Form.useForm()

  const onFinish = (values: any) => {
    let __qs = queryString.stringify(values, { arrayFormat: 'comma' })

    setQsQuery(__qs)
    setQsQuery(__qs)

    refetch()
  }

  return (
    <Form
      form={filterForm}
      name="advanced_search"
      className="advanced-search-form"
      onFinish={onFinish}
      layout={'vertical'}
    >
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item name={`patient_name`} label={`Paciente`}>
            <Input/>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name={`doctor_name`} label={`Doutor`}>
            <Input/>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button type="primary" htmlType="submit">
            Filtrar
          </Button>
          <Button
            style={{ margin: '0 8px' }}
            onClick={() => {
              filterForm.resetFields()
              setQsQuery('')
              setQsQuery('')
              refetch()
            }}
          >
            Resetar filtro
          </Button>
        </Col>
      </Row>
    </Form>
  )
})

export const AttendanceCalendarPage: React.FC<Props> = React.memo((props) => {
  const history = useHistory()
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })
  const [current_view, setCurrentVieew] = useState<any>('week')
  const [qs_query, setQsQuery] = useState('')

  const { isFetching, data, refetch } = useQuery('getAttendancesCalendar', () => qs.attendances.calendar(qs_query))
  const doctors = useQuery('getDoctors', () => qs.doctors.all())

  const create = useMutation((values: any) => qs.attendances.post(values), {
    onSuccess: (values) => {
      refetch()
      history.push(`/attendances/${values.data.id}`)
    }
  })
  const update = useMutation((values: any) => qs.attendances.patch(values), {
    onSuccess: () => {
      refetch()
    }
  })

  const handleItemClick = (e: AppointmentDblClickEvent | AppointmentClickEvent) => {
    // @ts-ignore
    let attedance_id = e.appointmentData.meta_attendance_id

    if (attedance_id) {
      history.push(`/attendances/${attedance_id}`)
    } else {
      Modal.confirm({
        closable: true,
        maskClosable: true,
        icon: <ExclamationCircleOutlined/>,
        content: 'Atendimento não foi lançado ainda, gostaria de lançar agora?',
        onOk() {
          notification.info({ message: 'Processando!' })
          let target_data: any = e.targetedAppointmentData

          create.mutate({
            doctor: target_data.meta_doctor_id,
            patient: target_data.meta_patient_id,
            date_start: target_data.startDate,
            date_end: target_data.endDate,
            attendance_type: 'SESSION',
            status: 'PENDENT'
          })
        },
        onCancel() {

        }
      })
    }
  }

  const handleItemDrag = (e: AppointmentUpdatingEvent) => {
    let new_data = e.newData
    let attedance_id = new_data.meta_attendance_id

    if (attedance_id) {
      notification.info({ message: 'Processando!' })

      update.mutate({
        id: attedance_id,
        date_start: new_data.startDate,
        date_end: new_data.endDate
      })

    } else {
      Modal.confirm({
        closable: true,
        maskClosable: true,
        icon: <ExclamationCircleOutlined/>,
        content: 'Atendimento não foi lançado ainda, gostaria de lançar agora?',
        onOk() {
          notification.info({ message: 'Processando!' })

          create.mutate({
            doctor: new_data.meta_doctor_id,
            patient: new_data.meta_patient_id,
            date_start: new_data.startDate,
            original_date_start: new_data.meta_start_time,
            date_end: new_data.endDate,
            attendance_type: 'SESSION',
            status: 'PENDENT'
          })
        },
        onCancel() {

        }
      })
    }
  }

  const handleCalendarWidth = () => {
    if (isTabletOrMobile) {
      return current_view === 'agenda' || current_view === 'day' ? '' : '220vw'
    }

    return ''
  }

  const resourcesData = doctors.data?.map(i => {
    return {
      id: i.id,
      text: i.name,
      color: Color('#30859f').rotate(i.id * 35).hex()
    }
  })

  return (
    <React.Fragment>
      <CustomPageHeader title={'Agenda - Atendimentos'}/>

      <AdvancedSearchForm refetch={refetch} setQsQuery={setQsQuery}/>

      <Layout.Content className="body-container" style={{ padding: 0 }}>
        <Spin spinning={isFetching} size="large">
          <Scheduler
            style={{ zIndex: 0 }}
            timeZone="America/Sao_Paulo"
            dataSource={data}
            // @ts-ignore
            views={views}
            currentView={current_view}
            onCurrentViewChange={(curr) => setCurrentVieew(curr)}
            defaultCurrentView={current_view}
            useDropDownViewSwitcher
            height={isTabletOrMobile ? '92vh' : '88vh'}
            width={handleCalendarWidth()}
            startDayHour={6}
            endDayHour={20}
            editing={{
              allowAdding: false,
              allowDeleting: false,
              allowDragging: !isTabletOrMobile,
              allowUpdating: true
            }}
            onAppointmentFormOpening={(e) => {
              e.cancel = true
            }}
            onAppointmentClick={(e) => {
              console.log(e)
              e.cancel = true
              handleItemClick(e)
            }}
            onAppointmentDblClick={(e) => {
              console.log(e)
              e.cancel = true
              handleItemClick(e)
            }}
            recurrenceEditMode="series"
            onAppointmentUpdating={(e) => {
              console.log('onAppointmentUpdating')
              console.log(e)
              e.cancel = true
              handleItemDrag(e)
            }}
          >
            <Resource dataSource={resourcesData} fieldExpr="doctorGroupId" label=""/>
          </Scheduler>
        </Spin>
      </Layout.Content>

      {renderRoutes(props.route?.routes)}
    </React.Fragment>
  )
})
