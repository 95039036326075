import {LoadingOutlined, PlusOutlined} from '@ant-design/icons'
import {Alert, Button, Divider, List, message, Popconfirm, Upload} from 'antd'
import * as React from 'react'
import {useEffect} from 'react'
import {useMutation, useQuery} from 'react-query'
import {getLocalAuthToken, SERVER_HOST} from '../../../services/api'
import {qs} from '../../queries'

type Props = {
  object_id: number | string | null | undefined
  object_type: 'attendance' | 'transaction' | 'patient' | 'estimate'
}

const UploaderAttachment = (props: Props) => {
  const [loading, setLoading] = React.useState(false)

  const files = useQuery(['getFiles', props.object_id], () => qs.attachments.filtered(props.object_id, props.object_type), {
    enabled: false,
    retry: false
  })
  const destroy_qs = useMutation((values: any) => qs.attachments.delete(values), {
    onSuccess: (values) => {
      files.refetch()
    }
  })

  useEffect(() => {
    files.refetch()
  }, [props.object_id])

  const handleChange = info => {
    if (info.file.status === 'uploading') {
      setLoading(true)
      return
    }

    if (info.file.status === 'done') {
      files.refetch()
    }

    setLoading(false)
  }

  const destroy = (item_id: any) => {
    message.info('Excluindo, por favor aguarde!')
    destroy_qs.mutate({ id: item_id })
  }

  const uploadButton = (
    <div>
      <Button icon={loading ? <LoadingOutlined/> : <PlusOutlined/>}>Enviar arquivo</Button>
    </div>
  )

  if (!props.object_id) {
    return (
      <Alert message="Crie o item primeiro!" type="info"/>
    )
  }

  return (
    <React.Fragment>
      <Upload
        name={'image'}
        showUploadList={false}
        multiple
        data={(_file) => {
          return {
            file_name: _file.name,
            [props.object_type]: props.object_id
          }
        }}
        action={`${SERVER_HOST}/api/attachments`}
        headers={{
          'Authorization': `Token ${getLocalAuthToken()}`
        }}
        onChange={handleChange}
      >
        {uploadButton}
      </Upload>

      <br/>
      <br/>
      <br/>

      <h4>Arquivos enviados:</h4>
      <Divider style={{ marginTop: 0 }}/>

      <List
        loading={files.isLoading}
        itemLayout="horizontal"
        dataSource={files.data?.results}
        renderItem={item => (
          <List.Item
            actions={[
              <a href={item.image} target="_blank">abrir</a>,

              <Popconfirm
                title="Gostaria de excluir?"
                onConfirm={() => destroy(item.id)}
                okText="Sim"
                cancelText="Não"
              >
                <a href={'#'} onClick={() => {}}>excluir</a>
              </Popconfirm>

            ]}
          >
            <List.Item.Meta
              description={item.file_name}
            />
          </List.Item>
        )}
      />
    </React.Fragment>
  )
}

export default UploaderAttachment