import { DeleteOutlined } from '@ant-design/icons'
import { Button, Divider, Form, Modal, Popconfirm, Select, Tabs } from 'antd'
import currency from 'currency.js'
import { FieldArray, FormikProvider, getIn, useFormik } from 'formik'
import * as React from 'react'
import { useEffect } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { RouteConfigComponentProps } from 'react-router-config'
import { useHistory, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { FCPF, FDate, FInput, FInputNumberRaw, FMoneyRaw, FormGroup, FSelect, FTextArea } from '../../../components/form/FormInputs'
import { BRL, Dec2BRL } from '../../../utils/formatters'
import UploaderAttachment from '../../Attendance/components/UploaderAttachment'
import { ESTIMATE_STATUS_SELECT } from '../../constants'
import { qs } from '../../queries'
import { EstimateModel } from '../../typings'
import EstimateInvoicesTab from '../components/EstimateInvoicesTab'
import { EstimateCardTab } from '../components/EstimateCardTab'

type EstimateDetailPageProps = RouteConfigComponentProps

type FormValues = { [K in keyof EstimateModel]?: EstimateModel[K] }

export const EstimateDetailPage: React.FC<EstimateDetailPageProps> = (props) => {
  const { item_id } = useParams<{ item_id: string }>()
  const history = useHistory()
  const queryClient = useQueryClient()

  const patients = useQuery('getPatients', () => qs.patients.all())
  const companies = useQuery('getCompanies', () => qs.companies.all())
  const procedures = useQuery('getProcedures', () => qs.procedures.all())

  const cur_item = useQuery(['getEstimate', item_id], () => qs.estimates.get(item_id), {
    enabled: false,
    retry: false
  })

  const create = useMutation(values => qs.estimates.post(values), {
    onSuccess: () => handleCancel()
  })

  const update = useMutation(values => qs.estimates.patch(values))

  const getContract = useMutation(values => qs.estimates.getContract(item_id))

  useEffect(() => {
    cur_item.refetch().then()
  }, [item_id])

  let mutating = create.isLoading || update.isLoading

  const handleCancel = () => {
    queryClient.removeQueries(['getEstimate', item_id], { inactive: true, stale: true })

    setTimeout(() => {
      history.push('/estimates')
    }, 200)
  }

  const formikBag = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      id: cur_item.data?.id ?? '',
      status: cur_item.data?.status ?? 'ACTIVE',
      observations: cur_item.data?.observations ?? '',
      contract_active: cur_item.data?.contract_active ?? false,
      guarantor_name: cur_item.data?.guarantor_name ?? '',
      guarantor_cpf: cur_item.data?.guarantor_cpf ?? '',
      guarantor_rg: cur_item.data?.guarantor_rg ?? '',
      guarantor_rg_issuer: cur_item.data?.guarantor_rg_issuer ?? '',
      guarantor_birthday: cur_item.data?.guarantor_birthday ?? '',
      guarantor_email: cur_item.data?.guarantor_email ?? '',
      guarantor_phone: cur_item.data?.guarantor_phone ?? '',
      guarantor_mobile_phone: cur_item.data?.guarantor_mobile_phone ?? '',
      guarantor_address: cur_item.data?.guarantor_address ?? '',
      guarantor_city: cur_item.data?.guarantor_city ?? '',
      guarantor_state: cur_item.data?.guarantor_state ?? '',
      guarantor_cep: cur_item.data?.guarantor_cep ?? '',
      procedures_items: cur_item.data?.estimate_procedures ?? [] as any,
      patient: cur_item.data?.patient_id ?? '' as any,
      company: cur_item.data?.company_id ?? '' as any
    },
    validationSchema: Yup.object().shape({
      patient: Yup.string().required(),
      status: Yup.string().required(),
      guarantor_email: Yup.string().email()
    }),
    onSubmit: (values: any) => {
      if (cur_item.data?.id) {
        update.mutate(values)
      } else {
        create.mutate(values)
      }
    }
  })

  const getSumAll = () => {
    let sum = currency(0)

    if (formikBag.values.procedures_items) {

      for (let i of formikBag.values.procedures_items) {
        sum = sum.add(i.amount)
      }
    }

    return sum.value
  }

  useEffect(() => {
    if (formikBag.values.procedures_items) {
      formikBag.values.procedures_items.forEach((o, idx) => {
        let _qtd = getIn(formikBag.values, `procedures_items[${idx}].qtd`)
        let _discount = getIn(formikBag.values, `procedures_items[${idx}].discount`)

        if (_qtd) {
          let _original_amount = getIn(formikBag.values, `procedures_items[${idx}].original_amount`)
          _original_amount = currency(_original_amount)

          if (_discount) {
            _original_amount = _original_amount.subtract(_discount)
          }

          _original_amount = _original_amount.multiply(_qtd)

          formikBag.setFieldValue(`procedures_items[${idx}].amount`, _original_amount.value)
        } else {
          formikBag.setFieldValue(`procedures_items[${idx}].amount`, 0)
        }
      })

    }

  }, [formikBag?.values?.procedures_items])

  return (
    <React.Fragment>
      <Modal
        title={cur_item.data?.id ? `Orçamento/Contrato #${cur_item.data.id}` : 'Novo'}
        visible={true}
        maskClosable
        destroyOnClose
        onCancel={handleCancel}
        width={850}
        closable={false}
        className="ant-modal-tabs-only"
        footer={[
          cur_item?.data?.id &&
          <Button key="3" type="default" onClick={() => getContract.mutate()} loading={getContract.isLoading}>Gerar Contrato</Button>,
          <Button key="2" type="default" onClick={handleCancel}>Fechar</Button>,
          <Button key="1" type="primary" onClick={formikBag.submitForm} loading={mutating}>Salvar</Button>
        ]}
      >
        {!cur_item.isLoading &&
          <FormikProvider value={formikBag}>
            <Form layout="vertical">
              <Tabs defaultActiveKey={'1'}>
                <Tabs.TabPane tab="Detalhes" key="1">
                  <FSelect name="patient" label="Paciente" options={patients.data?.map(i => ({ value: i.id, label: i.name }))}/>

                  <FormGroup>
                    <FSelect name="status" label="Status" options={ESTIMATE_STATUS_SELECT}/>
                    <FSelect name="company" label="Empresa" options={companies.data?.map(i => ({ value: i.id, label: i.name }))}/>
                  </FormGroup>

                  <FTextArea name="observations" label="Observações"/>

                </Tabs.TabPane>

                <Tabs.TabPane tab="Responsável" key="2">
                  <FInput name="guarantor_name" label="Nome"/>

                  <FormGroup>
                    <FCPF name="guarantor_cpf" label="CPF"/>
                    <FInput name="guarantor_rg" label="RG"/>
                    <FInput name="guarantor_rg_issuer" label="Orgão Emissor"/>
                    <FDate name="guarantor_birthday" label="Data de Nascimento"/>
                  </FormGroup>

                  <FormGroup>
                    <FInput name="guarantor_email" label="E-mail"/>
                    <FInput name="guarantor_phone" label="Telefone"/>
                    <FInput name="guarantor_mobile_phone" label="Celular"/>
                  </FormGroup>

                  <FInput name="guarantor_address" label="Endereço"/>

                  <FormGroup>
                    <FInput name="guarantor_city" label="Cidade"/>
                    <FInput name="guarantor_state" label="Estado"/>
                    <FInput name="guarantor_cep" label="CEP"/>
                  </FormGroup>
                </Tabs.TabPane>

                <Tabs.TabPane tab="Procedimentos" key="4">

                  <FieldArray
                    name="procedures_items"
                    render={arrayHelpers => (
                      <>
                        <Form.Item label="Selecione um procedimento">
                          <Select
                            showSearch
                            allowClear
                            defaultValue={''}
                            value={''}
                            onChange={(value: any) => {
                              if (procedures.data) {
                                for (let o of procedures.data) {
                                  if (o.id === value) {
                                    arrayHelpers.push({
                                      procedure_name: o.name,
                                      procedure: o.id,
                                      qtd: 1,
                                      amount: o.amount,
                                      discount: '0',
                                      original_amount: o.amount
                                    })
                                  }
                                }
                              }
                            }}
                            filterOption={(input, option) => {
                              // @ts-ignore
                              return String(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }}
                          >
                            {procedures.data?.map(i => ({ value: i.id, label: i.name })).map(i =>
                              <Select.Option key={i.value} value={i.value}>
                                {i.label}
                              </Select.Option>
                            )}
                          </Select>
                        </Form.Item>

                        <div className="ant-table">
                          <div className="ant-table-container">
                            <table>
                              <thead className="ant-table-thead">
                              <tr>
                                <th className="ant-table-cell" style={{ width: '40%' }}>Procedimento</th>
                                <th className="ant-table-cell">Valor Unit.</th>
                                <th className="ant-table-cell">Qtd</th>
                                <th className="ant-table-cell">Desconto (R$)</th>
                                <th className="ant-table-cell">Subtotal</th>
                                <th className="ant-table-cell">Ações</th>
                              </tr>
                              </thead>

                              <tbody className="ant-table-tbody">
                              {formikBag.values?.procedures_items?.map((item, index) => (
                                <tr key={index} className="ant-table-row ant-table-row-level-0">
                                  <td className="ant-table-cell" style={{ width: '40%' }}>{item.procedure_name}</td>
                                  <td className="ant-table-cell">
                                    {Dec2BRL(getIn(formikBag.values, `procedures_items[${index}].original_amount`))}
                                  </td>
                                  <td className="ant-table-cell">
                                    <FInputNumberRaw
                                      name={`procedures_items[${index}].qtd`}
                                      min={1}

                                    />
                                  </td>
                                  <td className="ant-table-cell">
                                    <FMoneyRaw
                                      name={`procedures_items[${index}].discount`}
                                    />
                                  </td>
                                  <td className="ant-table-cell">
                                    {Dec2BRL(getIn(formikBag.values, `procedures_items[${index}].amount`))}
                                  </td>
                                  <td className="ant-table-cell">
                                    <Popconfirm
                                      title="Tem certeza que gostaria de excluir esse item?"
                                      onConfirm={() => {
                                        arrayHelpers.remove(index)
                                      }}
                                      okText="Sim, excluir!"
                                      cancelText="Não"
                                    >
                                      <Button size="small" danger shape="circle" icon={<DeleteOutlined/>}/>
                                    </Popconfirm>
                                  </td>
                                </tr>
                              ))}
                              </tbody>

                            </table>
                          </div>
                        </div>
                      </>
                    )}
                  />

                  <Divider type="horizontal"/>

                  <h3 style={{ textAlign: 'right' }}>Total: {BRL(getSumAll())}</h3>

                </Tabs.TabPane>

                <Tabs.TabPane tab="Cobranças" key="5">
                  <EstimateInvoicesTab object_id={cur_item.data?.id} total_amount={getSumAll()}/>
                </Tabs.TabPane>

                <Tabs.TabPane tab="Cartão de Crédito" key="6">
                  {cur_item.data?.id && <EstimateCardTab
                    object_id={cur_item.data?.transaction_card_id}
                    estimate_id={cur_item.data?.id}
                    refetchEstimate={cur_item.refetch}
                  />}
                </Tabs.TabPane>

                <Tabs.TabPane tab="Anexos" key="3">
                  <UploaderAttachment object_id={cur_item.data?.id} object_type="estimate"/>
                </Tabs.TabPane>
              </Tabs>
            </Form>
          </FormikProvider>}
      </Modal>
    </React.Fragment>
  )
}
