import { notification } from 'antd'
import { useAppStore } from '../components/hooks'
import { apiAxios, openBlobApi, setLocalAuthToken } from '../services/api'
import queryClient from '../services/queryClient'
import toasterService from '../services/toasterService'
import {
  AttedanceEventType,
  AttendanceModel,
  CompanyModel,
  EstimateModel,
  EvolutionModel,
  PatientModel,
  ProcedureModel,
  TransactionModel,
  UserModel
} from './typings'

type GetRequestResult<T> = {
  results: T
}

type ResourceParams = {
  get_qs?: string
}

const apiResource = <Model>(domain: string, title: string, all_key?: string, params: ResourceParams = {}) => {
  return {
    all: async (query_string?: string) => {
      try {
        let path = `/${domain}`

        if (query_string) {
          path = `${domain}?${query_string}`
        }

        const { data } = await apiAxios.get<GetRequestResult<Model[]>>(path)
        return data.results
      } catch (e) {
        toasterService.sendErrors(e)
        return Promise.reject(e)
      }
    },
    get: async (item_id: number | string, query_string = params.get_qs) => {
      try {
        if (item_id === 'new') {
          return null
        }
        const { data } = await apiAxios.get<Model>(`/${domain}/${item_id}${query_string ? query_string : ''}`)
        return data
      } catch (e) {
        toasterService.sendErrors(e)
        return Promise.reject(e)
      }
    },
    post: async ({ form_data_keys, id, ...values }: any) => {
      try {
        let data = values

        if (form_data_keys) {
          for (let o of form_data_keys) {
            if (values[o]) {
              data = new FormData()
              Object.keys(values).forEach(k => {
                data.append(k, values[k])
              })
              break
            }
          }
        }

        let res = await apiAxios.post(`/${domain}`, data)
        notification.success({ message: `${title} Criado` })

        if (all_key) await queryClient.invalidateQueries(all_key)

        return res
      } catch (e) {
        toasterService.sendErrors(e)
        return Promise.reject(e)
      }
    },
    patch: async ({ id, form_data_keys, ...values }: any) => {
      try {
        let data = values

        if (form_data_keys) {
          for (let o of form_data_keys) {
            if (values[o]) {
              data = new FormData()
              Object.keys(values).forEach(k => {
                data.append(k, values[k])
              })
              break
            }
          }
        }

        let res = await apiAxios.patch(`/${domain}/${id}`, data)

        notification.success({ message: `${title} atualizado` })

        if (all_key) await queryClient.invalidateQueries(all_key)

        return res
      } catch (e) {
        toasterService.sendErrors(e)
        return Promise.reject(e)
      }
    },
    delete: async ({ id }: any) => {
      try {
        let res = await apiAxios.delete(`/${domain}/${id}`)

        notification.success({ message: `${title} excluído!` })

        if (all_key) await queryClient.invalidateQueries(all_key)

        return res
      } catch (e) {
        toasterService.sendErrors(e)
        return Promise.reject(e)
      }
    }
  }
}

const procedures = apiResource<ProcedureModel>('procedures', 'Procedimento', 'getProcedures')

const estimates = {
  ...apiResource<EstimateModel>('estimates', 'Orçamento', 'getEstimates'),
  get: async (item_id: number | string) => {
    if (item_id === 'new') {
      return null
    }
    const { data } = await apiAxios.get<EstimateModel>(`/estimates/${item_id}?include[]=estimate_procedures.procedure_name`)
    return data
  },
  createInvoice: async (estimate_id: number, values: any) => {
    try {
      let res = await apiAxios.post(`/estimates/${estimate_id}/create-invoices`, values)
      notification.success({ message: `Cobranças criadas com sucessos!` })
      return res
    } catch (e) {
      toasterService.sendErrors(e)
      return Promise.reject(e)
    }
  },
  getContract: async (estimate_id: number | string) => {
    try {
      return await openBlobApi(`/estimates/${estimate_id}/contract`)
    } catch (e) {
      toasterService.sendErrors(e)
      return Promise.reject(e)
    }
  }
}

const patients = {
  ...apiResource<PatientModel>('patients', 'Paciente', 'getPatients')
}

const doctors = apiResource<UserModel>('doctors', 'Doutor', 'getDoctors')

const setTokenStore = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      useAppStore.setState({ is_authenticated: true })
      resolve(true)
    }, 1000 * 2)
  })
}

const users = {
  ...apiResource<UserModel>('users', 'Usuários', 'getUsers'),
  getMe: async () => {
    const { data } = await apiAxios.get(`/users/me/`)
    return data
  },
  getAuthToken: async (values: any) => {
    try {
      let { data } = await apiAxios.post('/auth-token/', values)
      setLocalAuthToken(data.token)
      await setTokenStore()
      return data.token
    } catch (e) {
      notification.error({ message: 'Verifique as credenciais' })
      return Promise.reject(e)
    }
  },
  updateMe: async (values: any) => {
    try {

      let res = await apiAxios.patch(`/users/update-me`, values)

      notification.success({ message: `Perfil atualizado!` })

      await queryClient.invalidateQueries('getMe')

      return res
    } catch (e) {
      toasterService.sendErrors(e)
      return Promise.reject(e)
    }
  }
}

const attendances = {
  ...apiResource<AttendanceModel>('attendances', 'Atendimento', 'getAttendances'),
  calendar: async (_query_strings: string | null) => {
    let url = 'attendances/calendar'

    if (_query_strings) {
      url = `attendances/calendar?${_query_strings}`
    }

    const { data } = await apiAxios.get<GetRequestResult<AttedanceEventType[]>>(url)
    return data.results
  },
  copy_from_last: async (item_id: number | string) => {
    type Response = {
      success: boolean
    }

    const { data } = await apiAxios.get<Response>(`attendances/${item_id}/copy_from_last`)
    return data
  }
}

const evolutions = apiResource<EvolutionModel>('evolutions', 'Evolução', 'getEvolutions')

const transaction_cards = apiResource<any>('transaction-cards', 'Cartão', 'getTransactioCards')

const transactions = {
  ...apiResource<TransactionModel>('transactions', 'Transação', 'getTransactions', { get_qs: '?include[]=patient.id' }),
  printRpa: async (item_id: number | string) => {
    const { data } = await apiAxios.get(`/transactions/${item_id}/print_rpa`)

    let winPrint = window.open()!
    winPrint.document.write(atob(data.html_data))
    winPrint.document.close()
    winPrint.focus()
    winPrint.print()

    return data
  },
  byEstimate: async (item_id: number | string | null | undefined) => {
    type Response = {
      results: TransactionModel[]
    }

    const { data } = await apiAxios.get<Response>(`transactions?filter{estimate_id}=${item_id}&sort[]=id`)
    return data
  },
  charge: async (item_id: any) => {
    type Response = any

    const { data } = await apiAxios.post<Response>(`transactions/${item_id}/charge`, null)
    return data
  },
  get_slip: async (item_id: any) => {
    type Response = any
    const { data } = await apiAxios.post<Response>(`transactions/${item_id}/get_slip`, null)
    return data
  }
}

const attachments = {
  ...apiResource<any>('attachments', 'Anexos', 'getAttachments'),
  filtered: async (item_id: number | string | null | undefined, item_type) => {
    type Response = {
      results: {
        id: number,
        file_name: string,
        image: string,

      }[]
    }

    const { data } = await apiAxios.get<Response>(`attachments?filter{${item_type}_id}=${item_id}`)
    return data
  }
}

const companies = apiResource<CompanyModel>('companies', 'Empresa', 'getCompanies')

export const qs = {
  patients,
  companies,
  doctors,
  users,
  attendances,
  evolutions,
  transactions,
  transaction_cards,
  attachments,
  procedures,
  estimates
}

