import {Button, Col, Form, Modal, Row, Tabs} from 'antd'
import {FormikProvider, useFormik} from 'formik'
import * as React from 'react'
import {useEffect} from 'react'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {RouteConfigComponentProps} from 'react-router-config'
import {useHistory, useParams} from 'react-router-dom'
import * as Yup from 'yup'
import {FCPF, FDate, FInput, FormGroup} from '../../../components/form/FormInputs'
import {useMeQuery} from '../../../components/hooks'
import UploaderAttachment from '../../Attendance/components/UploaderAttachment'
import {qs} from '../../queries'
import {PatientModel} from '../../typings'

type PatientDetailPageProps = RouteConfigComponentProps

type FormValues = { [K in keyof PatientModel]?: PatientModel[K] | '' }

export const PatientDetailPage: React.FC<PatientDetailPageProps> = () => {
  const { is_admin_or_assistant } = useMeQuery()

  const { item_id } = useParams<{ item_id: string }>()
  const history = useHistory()
  const queryClient = useQueryClient()

  const cur_item = useQuery(['getPatient', item_id], () => qs.patients.get(item_id), {
    enabled: false,
    retry: false
  })

  const create = useMutation(values => qs.patients.post(values), {
    onSuccess: () => handleCancel()
  })
  const update = useMutation(values => qs.patients.patch(values))

  useEffect(() => {
    cur_item.refetch()
  }, [item_id])

  let mutating = create.isLoading || update.isLoading

  const handleCancel = () => {
    queryClient.removeQueries(['getPatient', item_id], { inactive: true, stale: true })

    setTimeout(() => {
      history.push('/patients')
    }, 200)
  }

  const formikBag = useFormik<FormValues>({
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      name: Yup.string().required(),
      mobile_phone: Yup.string().required(),
      email: Yup.string().email()
    }),
    initialValues: {
      id: cur_item.data?.id ?? '',
      name: cur_item.data?.name ?? '',
      cpf: cur_item.data?.cpf ?? '',
      rg: cur_item.data?.rg ?? '',
      rg_issuer: cur_item.data?.rg_issuer ?? '',
      birthday: cur_item.data?.birthday ?? '',
      email: cur_item.data?.email ?? '',
      phone: cur_item.data?.phone ?? '',
      mobile_phone: cur_item.data?.mobile_phone ?? '',
      address: cur_item.data?.address ?? '',
      city: cur_item.data?.city ?? '',
      state: cur_item.data?.state ?? '',
      cep: cur_item.data?.cep ?? ''
    },
    onSubmit: (values: any) => {
      if (cur_item.data?.id) {
        update.mutate(values)
      } else {
        create.mutate(values)
      }
    }
  })

  return (
    <React.Fragment>
      <Modal
        visible={true}
        maskClosable
        destroyOnClose
        onCancel={handleCancel}
        width={800}
        closable={false}
        className="ant-modal-tabs-only"
        footer={[
          <Button key="2" type="default" onClick={handleCancel}>Fechar</Button>,
          is_admin_or_assistant && <Button key="1" type="primary" onClick={formikBag.submitForm} loading={mutating}>Salvar</Button>
        ]}
      >
        {!cur_item.isLoading &&
          <FormikProvider value={formikBag}>
            <Form layout="vertical">
              <Tabs defaultActiveKey={'1'}>
                <Tabs.TabPane tab="Dados Pessoais" key="1">
                  <Row gutter={48}>
                    <Col span={24}>
                      <FInput name="name" label="Nome"/>

                      <FormGroup>
                        <FCPF name="cpf" label="CPF"/>
                        <FInput name="rg" label="RG"/>
                        <FInput name="rg_issuer" label="Órgão Emissor"/>
                        <FDate name="birthday" label="Data do Nascimento"/>
                      </FormGroup>

                      <FormGroup>
                        <FInput name="email" label="E-mail"/>
                        <FInput name="mobile_phone" label="Celular"/>
                        <FInput name="phone" label="Telefone"/>
                      </FormGroup>

                      <FInput name="address" label="Endereço"/>

                      <FormGroup>
                        <FInput name="city" label="Cidade"/>
                        <FInput name="state" label="Estado"/>
                        <FInput name="cep" label="CEP"/>
                      </FormGroup>
                    </Col>
                  </Row>
                </Tabs.TabPane>

                <Tabs.TabPane tab="Anexos" key="2">
                  <UploaderAttachment object_id={cur_item.data?.id} object_type="patient"/>
                </Tabs.TabPane>
              </Tabs>
            </Form>
          </FormikProvider>}
      </Modal>
    </React.Fragment>
  )
}
