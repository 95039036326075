import * as React from 'react'
import {useQuery} from 'react-query'
import create from 'zustand'
import {qs} from '../features/queries'
import {UserModel} from '../features/typings'
import {getLocalAuthToken} from '../services/api'

export const useMeQuery = () => {
  const res = useQuery<UserModel>('getMe', () => qs.users.getMe())
  const is_admin = res.data?.role === 'ADMIN'
  const is_assistant = res.data?.role === 'ASSISTANT'
  const is_doctor = res.data?.role === 'DOCTOR'
  const is_client = res.data?.role === 'CLIENT'
  const is_admin_or_assistant = is_admin || is_assistant
  const is_admin_or_assistant_or_is_doctor = is_admin || is_assistant || is_doctor

  return {
    me: res?.data,
    is_admin,
    is_assistant,
    is_doctor,
    is_client,
    is_admin_or_assistant,
    is_admin_or_assistant_or_is_doctor,
    ...res
  }
}

const _token = !!getLocalAuthToken()
export const useAppStore = create(set => ({
  is_authenticated: _token,
  setAuthentication: (new_state: boolean) => set(state => ({ is_authenticated: new_state }))
}))