import { CopyOutlined, CreditCardOutlined, DollarOutlined, FileTextOutlined, WhatsAppOutlined } from '@ant-design/icons'
import { Button, Form, message, Modal, Popover, Tabs } from 'antd'
import { FormikProvider, useFormik } from 'formik'
import * as React from 'react'
import { useEffect } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { RouteConfigComponentProps } from 'react-router-config'
import { useHistory, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { FDate, FInput, FMoney, FormGroup, FSelect, FSwitch } from '../../../components/form/FormInputs'
import UploaderAttachment from '../../Attendance/components/UploaderAttachment'
import { PAY_SELECT } from '../../constants'
import { qs } from '../../queries'
import { TransactionModel } from '../../typings'

type TransactionDetailPageProps = RouteConfigComponentProps

type FormValues = { [K in keyof TransactionModel]?: TransactionModel[K] | '' }

export const IncomeDetailPage: React.FC<TransactionDetailPageProps> = (props) => {
  const { item_id } = useParams<{ item_id: string }>()
  const history = useHistory()
  const queryClient = useQueryClient()

  const cur_item = useQuery(['getTransaction', item_id], () => qs.transactions.get(item_id), {
    enabled: false,
    retry: false
  })
  const patients = useQuery('getPatients', () => qs.patients.all())
  const estimates = useQuery('getEstimates', () => qs.estimates.all())

  const create = useMutation(values => qs.transactions.post(values), {
    onSuccess: () => {
      handleCancel()
    }
  })
  const update = useMutation(values => qs.transactions.patch(values))
  const charge_trx = useMutation((values: any) => qs.transactions.charge(values))
  const get_slip_trx = useMutation((values: any) => qs.transactions.get_slip(values))

  useEffect(() => {
    cur_item.refetch()
  }, [item_id])

  let mutating = create.isLoading || update.isLoading
  let charging = charge_trx.isLoading
  let get_slip_loading = get_slip_trx.isLoading

  const handleCancel = () => {
    queryClient.removeQueries(['getTransaction', item_id], { inactive: true, stale: true })

    setTimeout(() => {
      history.push('/transactions')
    }, 200)
  }

  const formikBag = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      id: cur_item.data?.id ?? '',
      amount: cur_item.data?.amount ?? '',
      due_date: cur_item.data?.due_date ?? '',
      is_paid: cur_item.data?.is_paid ?? false,
      payment_date: cur_item.data?.payment_date ?? '',
      description: cur_item.data?.description ?? '',
      payment_type: cur_item.data?.payment_type ?? '',
      patient: cur_item.data?.patient_id ?? '' as any,
      estimate: cur_item.data?.estimate_id ?? '' as any,
      moving_type: 'INCOME'
    },
    validationSchema: Yup.object().shape({
      description: Yup.string().required(),
      payment_type: Yup.string().required(),
      patient: Yup.string().required(),
      amount: Yup.string().required(),
      due_date: Yup.string().required()
    }),
    onSubmit: (values: any) => {
      if (cur_item.data?.id) {
        update.mutate(values)
      } else {
        create.mutate(values)
      }
    }
  })

  return (
    <React.Fragment>
      <Modal
        title={cur_item?.data?.id ? 'Editar cobrança' : 'Nova cobrança'}
        visible={true}
        maskClosable
        destroyOnClose
        onCancel={handleCancel}
        width={750}
        className="ant-modal-tabs-only"
        closable={false}
        footer={[
          cur_item?.data?.id &&
          <Button
            key="3" icon={<CreditCardOutlined/>} type="default" loading={charging} disabled={charging}
            onClick={() => charge_trx.mutate(cur_item?.data?.id)}
          >
            Cobrar Cartão
          </Button>,

          cur_item?.data?.id &&
          <Popover
            content={
              <>
                <Button
                  href={`https://api.whatsapp.com/send/?phone=55${cur_item.data?.patient?.mobile_phone}&text=${get_slip_trx?.data?.slip_url}`}
                  target="_blank"
                  icon={<WhatsAppOutlined/>} type="default"
                  loading={get_slip_loading} disabled={get_slip_loading}
                >
                  Enviar via WhatsApp
                </Button>
                &nbsp;
                &nbsp;
                &nbsp;
                <Button
                  icon={<CopyOutlined/>} type="default" loading={get_slip_loading} disabled={get_slip_loading}
                  onClick={() => {
                    navigator.clipboard.writeText(get_slip_trx?.data?.slip_url)
                    message.success('Link copiado!')
                  }}
                >
                  Copiar
                </Button>

              </>
            }
            title="Link Boleto"
            trigger="click"
          >
            <Button
              key="4" icon={<DollarOutlined/>} type="default" loading={get_slip_loading} disabled={get_slip_loading}
              onClick={() => get_slip_trx.mutate(cur_item?.data?.id)}
            >
              Link Boleto
            </Button>
          </Popover>,

          cur_item?.data?.id &&
          <Popover
            content={
              <>
                <FormikProvider value={formikBag}>
                  <Form layout="vertical">
                    <FSelect
                      name="nfse_company" label="Selecione a empresa"
                      options={[{ label: 'Empresa 1', value: '1' }, { label: 'Empresa 2', value: '2' }]}
                    />
                  </Form>
                </FormikProvider>

                <br/>
                <Button icon={<CopyOutlined/>} type="primary" onClick={() => { }}>Gerar</Button>
              </>
            }
            title="Gerar NFS-e"
            trigger="click"
          >
            <Button key="3" icon={<FileTextOutlined/>} type="default">Gerar NFS-e</Button>
          </Popover>,

          <Button key="2" type="default" onClick={handleCancel}>Fechar</Button>,
          <Button key="1" type="primary" onClick={formikBag.submitForm} loading={mutating}>Salvar</Button>
        ]}
      >
        {!cur_item.isLoading && !patients.isLoading && !estimates.isLoading &&
          <FormikProvider value={formikBag}>
            <Tabs defaultActiveKey={'1'}>
              <Tabs.TabPane tab="Detalhes" key="1">
                <Form layout="vertical">
                  <FormGroup>
                    <FSelect name="patient" label="Paciente" options={patients.data?.map(i => ({ value: i.id, label: i.name }))}/>
                    <FSelect name="estimate" label="Orçamento/Contrato" options={estimates.data?.map(i => (
                      {
                        value: i.id,
                        label: `#${i.id} - ${i.patient_name}`
                      }
                    ))}
                    />
                  </FormGroup>

                  <FInput name="description" label="Descrição"/>

                  <FormGroup>
                    <FMoney name="amount" label="Valor"/>
                    <FDate name="due_date" label="Vencimento"/>
                    <FSelect name="payment_type" label="Forma de Pagamento" options={PAY_SELECT}/>
                  </FormGroup>

                  <FormGroup>
                    <FSwitch name="is_paid" label="Foi pago?"/>

                    {formikBag.values.is_paid &&
                      <>
                        <FDate name="payment_date" label="Data de pagamento"/>
                      </>}

                    <></>
                  </FormGroup>

                </Form>
              </Tabs.TabPane>

              <Tabs.TabPane tab="Anexos" key="3">
                <UploaderAttachment object_id={cur_item.data?.id} object_type="transaction"/>
              </Tabs.TabPane>
            </Tabs>
          </FormikProvider>}
      </Modal>
    </React.Fragment>
  )
}
