import { Button, Divider, Form } from 'antd'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { qs } from '../../queries'
import { FCPF, FInput, FormGroup, FSelect } from '../../../components/form/FormInputs'
import { FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'

type Props = {
  object_id: number | string | null | undefined
  estimate_id: number | string
  refetchEstimate: any
}

export const EstimateCardTab = (props: Props) => {
  const queryClient = useQueryClient()

  console.log('props card', props)

  const [change_card, setChangeCard] = useState(false)

  const { data, ...qer } = useQuery(['TransactionCard', props.object_id], () => qs.transaction_cards.get(props.object_id as any), {
    enabled: false,
    retry: false
  })

  const create = useMutation(values => qs.transaction_cards.post(values), {
    onSuccess: () => {
      setChangeCard(false)
      props.refetchEstimate()
    }
  })

  useEffect(() => {
    if (props.object_id && props.estimate_id) {
      qer.refetch()
    }
  }, [props.object_id])

  const formikBag = useFormik<any>({
    enableReinitialize: true,
    initialValues: {
      estimate_id: props.estimate_id,

    },
    validationSchema: Yup.object().shape({
      buyer_name: Yup.string().required(),
      card_holder_name: Yup.string().required(),
      card_number: Yup.string().required(),
      card_expiration_month: Yup.string().required(),
      card_expiration_year: Yup.string().required(),
      card_cvc: Yup.string().required(),
    }),
    onSubmit: (values: any) => {
      create.mutate(values)
    }
  })

  if (!data?.id || (data?.id && change_card)) {
    return (
      <>
        <FormikProvider value={formikBag}>
          <Form layout="vertical">
            <h4>Dados do titular</h4>
            <FormGroup>
              <FInput name="buyer_name" label="Nome"/>
              <FCPF name="buyer_cpf" label="CPF"/>
              <FInput name="buyer_phone" label="Telefone/celular"/>
            </FormGroup>

            <FormGroup>
              <FInput name="buyer_address" label="Endereço"/>
              <FInput name="buyer_city" label="Cidade"/>
              <FInput name="buyer_state" label="Estado"/>
              <FInput name="buyer_postal_code" label="CEP"/>
            </FormGroup>

            <Divider type="vertical"/>

            <h4>Dados do cartão</h4>
            <FInput name="card_holder_name" label="Nome no Cartão"/>

            <FInput name="card_number" label="Número do Cartão"/>

            <FormGroup>
              <FSelect
                name="card_expiration_month" label="Mês"
                options={['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map(i => ({ label: i, value: i }))}
              />
              <FSelect
                name="card_expiration_year" label="Ano"
                options={Array.from({ length: 21 }, (x, i) => i).map(i => ({ label: `${i + 22}`, value: `${i + 22}` }))}
              />

              <FInput name="card_cvc" label="Código de segurança"/>
            </FormGroup>

            <Button type="primary" onClick={formikBag.submitForm} loading={create.isLoading} disabled={create.isLoading}>
              Salvar Cartão
            </Button>

            {change_card && <Button type="default" onClick={() => setChangeCard(false)}>Cancelar</Button>}
          </Form>
        </FormikProvider>
      </>
    )
  }

  return (
    <>
      <h4>Cartão cadastrado: <b>{data?.first4_digits} **** **** ****</b></h4>
      <br/>
      <Button type="default" onClick={() => setChangeCard(true)}>Alterar Cartão</Button>
    </>
  )
}

