import currency from 'currency.js'
import moment from 'moment'

export function dateFormat(params: string | undefined) {
  let formated = params

  if (typeof formated !== 'undefined' && formated !== null && formated !== '') {
    formated = moment(formated).format('DD/MM/YYYY')
  }
  return formated
}

export function shortDateFormat(params: string | undefined) {
  let formated = params

  if (typeof formated !== 'undefined' && formated !== null && formated !== '') {
    formated = moment(formated).format('DD/MM/YY')
  }
  return formated
}

export function dateTimeFormat(params: string | undefined) {
  let formated = params

  if (typeof formated !== 'undefined' && formated !== null && formated !== '') {
    formated = moment(formated).format('DD/MM/YY HH:mm')
  }
  return formated
}

export const getAge = (birthdate: string | undefined, include_age = false) => {
  if (!birthdate) return ''
  let years = moment().diff(moment(birthdate), 'years')
  return include_age ? `${years} anos` : years
}

export const BRL = (value: any) => currency(value, { separator: '.', decimal: ',', symbol: 'R$ ' }).format()

export const Dec2BRL = (value: any) => currency(currency(value).value, { separator: '.', decimal: ',', symbol: '' }).format()
