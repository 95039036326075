import {MenuOutlined, ZoomInOutlined} from '@ant-design/icons'
import {Button, Col, Form, Input, Layout, PageHeader, Row, Table} from 'antd'
import {ColumnsType} from 'antd/lib/table/interface'
import * as React from 'react'
import {useState} from 'react'
import {IMask} from 'react-imask'
import {useQuery} from 'react-query'
import {renderRoutes, RouteConfigComponentProps} from 'react-router-config'
import {Link, useHistory} from 'react-router-dom'
import {useRecoilState} from 'recoil'
import {useMeQuery} from '../../../components/hooks'
import {menuCollapseState} from '../../../state/atoms'
import {qs} from '../../queries'
import {PatientModel} from '../../typings'

const queryString = require('query-string')
const cpfMasked = IMask.createMask({
  mask: '000.000.000-00'
})

const AdvancedSearchForm = React.memo(({ setQsQuery, refetch }: { setQsQuery: (data: any) => void, refetch: () => void }) => {
  const [filterForm] = Form.useForm()

  const onFinish = (values: any) => {
    let __qs = queryString.stringify(values, { arrayFormat: 'comma' })

    setQsQuery(__qs)
    setQsQuery(__qs)

    refetch()
  }

  return (
    <Form
      form={filterForm}
      name="advanced_search"
      className="advanced-search-form"
      onFinish={onFinish}
      layout={'vertical'}
    >
      <Row gutter={24}>
        <Col span={10}>
          <Form.Item name={'filter{name.icontains}'} label="Nome">
            <Input/>
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item name={'filter{cpf.icontains}'} label="CPF">
            <Input/>
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item name={`filter{rg.icontains}`} label="RG">
            <Input/>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button type="primary" htmlType="submit">
            Filtrar
          </Button>
          <Button
            style={{ margin: '0 8px' }}
            onClick={() => {
              filterForm.resetFields()
              setQsQuery('')
              setQsQuery('')
              setTimeout(() => {
                refetch()
              }, 200)
            }}
          >
            Resetar filtro
          </Button>
        </Col>
      </Row>
    </Form>
  )
})

type Props = RouteConfigComponentProps

export const PatientListPage: React.FC<Props> = (props) => {
  const [qs_query, setQsQuery] = useState('')
  const history = useHistory()
  const [collapsed, setCollapsed] = useRecoilState(menuCollapseState)

  const { isFetching, data, refetch } = useQuery('getPatients', () => qs.patients.all(qs_query))
  const { is_admin_or_assistant } = useMeQuery()

  const grid_columns: ColumnsType<PatientModel> = [
    {
      title: 'Nome',
      width: 200,
      render: (record: PatientModel) => {
        return <>
          {record.name}
          <br/> CPF: {record.cpf && cpfMasked.resolve(record.cpf)}
          <br/> RG: {record.rg}
        </>
      }
    },
    {
      title: 'E-mail',
      width: 150,
      dataIndex: 'email'
    },
    {
      title: 'Telefone',
      width: 200,
      render: (record: PatientModel) => {
        return <>
          Celular: {record.mobile_phone}
          <br/> Telefone: {record.phone}
        </>
      }
    },
    {
      title: 'Ações',
      dataIndex: 'id',
      align: 'right',
      width: 20,
      render: (value) => (
        <Link to={`/patients/${value}`}><ZoomInOutlined style={{ fontSize: '16px', marginRight: 5 }}/></Link>
      )
    }
  ]

  return (
    <React.Fragment>
      <PageHeader
        ghost={false}
        title={'Pacientes'}
        onBack={() => setCollapsed(!collapsed)}
        backIcon={<MenuOutlined/>}
      />

      <AdvancedSearchForm refetch={refetch} setQsQuery={setQsQuery}/>

      <Layout.Content className="body-container">
        {is_admin_or_assistant && <Button type="primary" onClick={() => history.push('/patients/new')}>Novo</Button>}

        <Table columns={grid_columns} dataSource={data} rowKey="id" loading={isFetching}/>

        {renderRoutes(props.route?.routes)}
      </Layout.Content>
    </React.Fragment>
  )
}
