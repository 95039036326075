import {
  CalculatorOutlined,
  CalendarOutlined,
  ContactsOutlined,
  FileDoneOutlined,
  FolderOpenOutlined,
  LogoutOutlined,
  ScheduleOutlined,
  TeamOutlined,
  UserOutlined
} from '@ant-design/icons'
import {Drawer, Layout, Menu} from 'antd'
import * as React from 'react'
import {useMediaQuery} from 'react-responsive'
import {Link, useLocation} from 'react-router-dom'
import {useRecoilState} from 'recoil'
import logo_icon from '../../assets/logo_team.png'
import logo from '../../assets/logo_team.png'
import {menuCollapseState} from '../../state/atoms'
import {useMeQuery} from '../hooks'
import InfoVersion from './InfoVersion'

type Props = {}

const { Sider } = Layout

export const Sidebar: React.FC<Props> = (props) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })
  const { pathname } = useLocation()
  const [collapsed, setCollapsed] = useRecoilState(menuCollapseState)

  const { me, is_admin, is_admin_or_assistant } = useMeQuery()

  const AppMenu = () => (
    <React.Fragment>
      <Menu theme="dark" mode="inline" defaultSelectedKeys={['/']} selectedKeys={[pathname]}>
        <Menu.Item key="/attendances" icon={<CalendarOutlined/>}>
          <Link to={'/attendances'}>Atendimentos (Calendário)</Link>
        </Menu.Item>

        <Menu.Item key="/attendances-list" icon={<ScheduleOutlined/>}>
          <Link to={'/attendances-list'}>Atendimentos (Tabela)</Link>
        </Menu.Item>

        <Menu.Item key="/patients" icon={<ContactsOutlined/>}>
          <Link to={'/patients'}>Pacientes</Link>
        </Menu.Item>

        <Menu.Item key="/estimates" icon={<FileDoneOutlined/>}>
          <Link to={'/estimates'}>Orçamentos/Contratos</Link>
        </Menu.Item>

        <Menu.Item key="/procedures" icon={<FolderOpenOutlined/>}>
          <Link to={'/procedures'}>Procedimentos</Link>
        </Menu.Item>

        {is_admin_or_assistant &&
          <Menu.Item key="/transactions" icon={<CalculatorOutlined/>}>
            <Link to={'/transactions'}>Financeiro</Link>
          </Menu.Item>}

        {is_admin_or_assistant &&
          <Menu.Item key="/team" icon={<TeamOutlined/>}>
            <Link to={'/team'}>Equipe</Link>
          </Menu.Item>}

        {/*{is_admin &&*/}
        {/*  <Menu.Item key="reports" icon={<BarChartOutlined/>}>*/}
        {/*    <Link to={'/child/5'}>Relatórios</Link>*/}
        {/*  </Menu.Item>}*/}

        {/*{is_admin &&*/}
        {/*  <Menu.Item key="/configs" icon={<SettingOutlined/>}>*/}
        {/*    <Link to={'/configs'}>Configurações</Link>*/}
        {/*  </Menu.Item>}*/}

        <Menu.Item key="/profile" icon={<UserOutlined/>}>
          <Link to={'/profile'}>
            <span style={{ color: '#999', marginRight: 4 }}>Olá, </span>
            <span>{me?.name}</span>
          </Link>
        </Menu.Item>

        <Menu.Item key="SignOut" icon={<LogoutOutlined/>}>
          <Link to={`/login`}>Sair</Link>
        </Menu.Item>
      </Menu>

      <InfoVersion/>
    </React.Fragment>
  )

  return (
    <React.Fragment>
      <Drawer
        placement="left"
        visible={collapsed}
        onClose={(collapsed) => { setCollapsed(!collapsed) }}
        closeIcon={false}
        bodyStyle={{
          padding: 0,
          backgroundColor: '#000000',
          height: '100vh'
        }}
        style={{
          padding: 0,
          height: '100vh'
        }}
      >
        <img className="logo" src={logo}/>
        <AppMenu/>
      </Drawer>

      {!isTabletOrMobile &&
        <Sider
          theme="dark"
          defaultCollapsed={true}
          collapsed={true}
          trigger={null}
          collapsedWidth={60}
        >
          {!isTabletOrMobile && <img className="logo-icon" src={logo_icon}/>}
          <AppMenu/>
        </Sider>}
    </React.Fragment>
  )
}

export const ClientSidebar: React.FC<Props> = (props) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })
  const { pathname } = useLocation()
  const [collapsed, setCollapsed] = useRecoilState(menuCollapseState)

  const AppMenu = () => (
    <React.Fragment>
      <Menu theme="light" mode="inline" defaultSelectedKeys={['/']} selectedKeys={[pathname]}>
        <Menu.Item key="/attendances" icon={<CalendarOutlined/>}>
          <Link to={'/attendances'}>Atendimentos</Link>
        </Menu.Item>

        <Menu.Item key="/transactions" icon={<CalculatorOutlined/>}>
          <Link to={'/transactions'}>Financeiro</Link>
        </Menu.Item>

        <Menu.Item key="SignOut" icon={<LogoutOutlined/>}>
          <Link to={`/login`}>Sair</Link>
        </Menu.Item>
      </Menu>

      <InfoVersion/>
    </React.Fragment>
  )

  return (
    <React.Fragment>
      <Drawer
        placement="left"
        visible={collapsed}
        onClose={(collapsed) => { setCollapsed(!collapsed) }}
        closeIcon={false}
        bodyStyle={{
          padding: 0
        }}
      >
        <img className="logo" src={logo}/>
        <AppMenu/>
      </Drawer>

      {!isTabletOrMobile &&
        <Sider
          theme="dark"
          defaultCollapsed={true}
          collapsed={true}
          trigger={null}
          collapsedWidth={60}
        >
          {!isTabletOrMobile && <img className="logo-icon" src={logo_icon}/>}
          <AppMenu/>
        </Sider>}
    </React.Fragment>
  )
}
