import {DeleteOutlined, ExclamationCircleOutlined} from '@ant-design/icons'
import {Button, Col, Form, Modal, Row, Tabs} from 'antd'
import {FormikProvider, useFormik} from 'formik'
import * as React from 'react'
import {useEffect} from 'react'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {renderRoutes, RouteConfigComponentProps} from 'react-router-config'
import {useHistory, useParams} from 'react-router-dom'
import * as Yup from 'yup'
import {FDateTime, FInput, FormGroup, FSelect} from '../../../components/form/FormInputs'
import {qs} from '../../queries'
import {AttendanceModel} from '../../typings'
import UploaderAttachment from '../components/UploaderAttachment'

type AttendanceDetailPageProps = RouteConfigComponentProps

type FormValues = { [K in keyof AttendanceModel]?: AttendanceModel[K] | '' }

const TYPE_SELECT = [
  { value: 'SESSION', label: 'Procedimento' },
  { value: 'EXAM', label: 'Avaliação' }
]

export const AttendanceDetailPage: React.FC<AttendanceDetailPageProps> = (props) => {
  const { item_id } = useParams<{ item_id: string }>()
  const history = useHistory()
  const queryClient = useQueryClient()

  const IS_TABLE_VIEW = history.location.pathname.indexOf('list') > -1
  const ALL_QUERY_KEY = IS_TABLE_VIEW ? 'getAttendances' : 'getAttendancesCalendar'
  const ROOT_PATH = IS_TABLE_VIEW ? '/attendances-list' : '/attendances'

  const doctors = useQuery('getDoctors', () => qs.doctors.all())
  const patients = useQuery('getPatients', () => qs.patients.all())
  const procedures = useQuery('getProcedures', () => qs.procedures.all())

  const cur_item = useQuery(['getAttendance', item_id], () => qs.attendances.get(item_id), {
    enabled: false,
    retry: false
  })

  const create = useMutation(values => qs.attendances.post(values), {
    onSuccess: (_values) => {
      if (!IS_TABLE_VIEW) {
        queryClient.invalidateQueries(ALL_QUERY_KEY)
      }

      queryClient.removeQueries(['getAttendance', item_id], { inactive: true, stale: true })

      history.push(`/attendances/${_values.data.id}`)
    }
  })
  const update = useMutation((values: any) => qs.attendances.patch(values), {
    onSuccess: () => {
      if (!IS_TABLE_VIEW) {
        queryClient.invalidateQueries(ALL_QUERY_KEY)
      }
    }
  })
  const destroy = useMutation((values: any) => qs.attendances.delete(values), {
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_QUERY_KEY)
      handleCancel()
    }
  })

  useEffect(() => {
    cur_item.refetch()
  }, [item_id])

  let mutating = create.isLoading || update.isLoading

  const handleCancel = () => {
    queryClient.removeQueries(['getAttendance', item_id], { inactive: true, stale: true })

    setTimeout(() => {
      history.push(ROOT_PATH)
    }, 200)
  }

  const handleChangeStatus = async (new_status: string) => {
    if (cur_item.data) {
      await update.mutateAsync({
        id: cur_item.data.id,
        status: new_status
      })

      await cur_item.refetch()
    }
  }

  const handleDestroy = () => {
    Modal.confirm({
      closable: true,
      maskClosable: true,
      icon: <ExclamationCircleOutlined/>,
      title: 'Tem certeza que gostaria de excluir esse atendimento?',
      content: 'Todos os registros vinculados tais como: intervenções e anexos também serão excluídos!',
      onOk() {
        destroy.mutate(cur_item.data)
      },
      onCancel() {

      }
    })
  }

  const formikBag = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      id: cur_item.data?.id ?? '',
      doctor: cur_item.data?.doctor_id ?? '',
      patient: cur_item.data?.patient_id ?? '',
      status: cur_item.data?.status ?? '',
      attendance_type: cur_item.data?.attendance_type ?? '',
      procedures: cur_item.data?.procedures ?? [],
      date_start: cur_item.data?.date_start ?? '',
      date_end: cur_item.data?.date_end ?? '',
      observations: cur_item.data?.observations ?? ''
    },
    validationSchema: Yup.object().shape({
      doctor: Yup.string().required(),
      patient: Yup.string().required(),
      attendance_type: Yup.string().required(),
      date_start: Yup.string().required()
      // date_end: Yup.string().required(),
    }),
    onSubmit: async (values: any) => {
      values = JSON.parse(JSON.stringify(values))

      if (!cur_item.data?.id) {
        values['status'] = 'PENDENT'
      }

      if (cur_item.data?.id) {
        update.mutate(values)
      } else {
        create.mutate(values)
      }
    }
  })

  return (
    <React.Fragment>
      <Modal
        visible={true}
        maskClosable
        destroyOnClose
        onCancel={handleCancel}
        width={800}
        closable={false}
        className="ant-modal-tabs-only"
        footer={[
          <div key="1" className="attendance-buttons">
            <Button key="4" type="default" onClick={handleCancel}>Fechar</Button>

            {cur_item.data?.id && <>
              <Button key="5" type="dashed" danger onClick={handleDestroy} icon={<DeleteOutlined/>} loading={destroy.isLoading}>Excluir</Button>

              {['PENDENT', 'CONFIRMED'].includes(cur_item.data?.status) &&
                <Button key="3" type="dashed" danger onClick={() => handleChangeStatus('CANCELED')}>Cancelar</Button>}

              {cur_item.data?.status === 'PENDENT' &&
                <Button key="10" type="primary" onClick={() => handleChangeStatus('CONFIRMED')}>Confirmar</Button>}

              {cur_item.data?.status === 'CONFIRMED' &&
                <Button key="2" type="primary" onClick={() => handleChangeStatus('FINISHED')}>Finalizar</Button>}
            </>}

            <Button key="1" type="primary" onClick={formikBag.submitForm} loading={mutating}>Salvar</Button>
          </div>
        ]}
      >
        {!cur_item.isLoading && !doctors.isLoading && !patients.isLoading &&
          <FormikProvider value={formikBag}>
            <Form layout="vertical">
              <Tabs defaultActiveKey={'1'}>
                <Tabs.TabPane tab="Detalhes" key="1">
                  <Row gutter={48}>
                    <Col span={24}>
                      <FormGroup>
                        <FSelect name="doctor" label="Doutor" options={doctors.data?.map(i => ({ value: i.id, label: i.name }))}/>
                        <FSelect name="patient" label="Paciente" options={patients.data?.map(i => ({ value: i.id, label: i.name }))}/>
                      </FormGroup>

                      <FormGroup>
                        <FSelect name="attendance_type" label="Tipo" options={TYPE_SELECT}/>
                        <FDateTime name="date_start" label="Data do Atendimento"/>
                      </FormGroup>

                      <FSelect
                        name="procedures" label="Procedimentos" mode="multiple"
                        options={procedures.data?.map(i => ({ value: i.id, label: i.name }))}
                      />

                      {/*<FDateTime name="date_end" label="Data de Encerramento"/>*/}

                      <FInput name="observations" label="Observações"/>
                    </Col>
                  </Row>
                </Tabs.TabPane>

                <Tabs.TabPane tab="Anexos" key="3">
                  <UploaderAttachment object_id={cur_item.data?.id} object_type="attendance"/>
                </Tabs.TabPane>
              </Tabs>
            </Form>
          </FormikProvider>}

        {renderRoutes(props.route?.routes)}
      </Modal>
    </React.Fragment>
  )
}
